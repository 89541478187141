import uniq from 'lodash.uniq';
import { uidsToObjects, getMongoUrlPrefix } from '@/helpers/misc.js';

// COMMON
const envFromApi = (env, dft) => {
    return (env === '') ? JSON.parse(dft) : JSON.parse(env);
}
const envToApi = (env) => {
    return (Object.keys(env).length === 0) ? '' : JSON.stringify(env);
}

// EXTENSIONS
export const extensionFromApi = (e) => {
    if (typeof e.categories === 'string')
        e.categories = e.categories.split(',');
    e.categories = e.categories.filter((c) => c.length > 0);
    e.defaultVersionUid = e.default_version.item;
    e.currentDefaultVersionUid = e.defaultVersionUid;

    delete e.default_version;
    return e;
};
export const extensionsFromApi = (e) => e.map((x) => extensionFromApi(x));

export const extensionToApi = (e) => {
    if (typeof e.categories !== 'string')
        e.categories = e.categories.join(',');

    delete e.defaultVersionUid;
    delete e.currentDefaultVersionUid;
    delete e.versions;
    return e;
};
export const extensionsToApi = (e) => e.map((x) => extensionToApi(x));

// PROJECT
export const getProjectExtensionVersionUids = (p, extension) => {
    const ext = p.project_extensions.find((e) => e.extension === extension);
    if (!ext) return [];

    const vUids = [ext.version];
    if (extension in p.site_extensions) vUids.push(...p.site_extensions[extension].map((x) => x.version));
    if (extension in p.user_extensions) vUids.push(...p.user_extensions[extension].map((x) => x.version));
    return uniq(vUids);
}

export const projectFromApi = (p, storeState) => {
    p.env = envFromApi(p.env, '[]');
    p.site_env = envFromApi(p.site_env, '{}');
    p.user_env = envFromApi(p.user_env, '{}');
    if (p.mongo_user && p.mongo_password && p.mongo_url.includes('@'))
        p.mongo_url = p.mongo_url.slice(p.mongo_url.indexOf('@') + 1);

    // reformat extensions to get readily-usable data
    if (p.project_extensions && p.project_extensions.length > 0) {
        const projectExtVersions = uidsToObjects(storeState, 'version', p.project_extensions, {
            keepOrder: true, packed: true });
        p.project_extensions = projectExtVersions.map((ver, i) => {
            const e = uidsToObjects(storeState, 'extension', [ver.extension.item], { single: true });
            const overrideEnv = envFromApi(p.project_extensions[i].rel.env, '[]');
            const version = p.project_extensions[i].rel.is_default ? '__default' : ver.uid;
            return { uid: e.uid, extension: e.name, version, overrideEnv };
        });
        p.project_extensions.sort((v1, v2) => v1.extension < v2.extension ? -1 : 1);
    } else {
        p.project_extensions = [];
    }
    p.project_extensions.sort((v1, v2) => v1.extension < v2.extension ? -1 : 1);

    if (p.site_extensions && p.site_extensions.length > 0) {
        const siteExtVersions = uidsToObjects(storeState, 'version', p.site_extensions, {
            keepOrder: true, packed: true });
        p.site_extensions = siteExtVersions.reduce((acc, ver, i) => {
            const e = uidsToObjects(storeState, 'extension', [ver.extension.item], { single: true });
            const s = storeState.site.sites.find((s) => s.code === p.site_extensions[i].rel.site);
            const item = { site: s.uid, code: s.code, name: s.name, version: ver.uid };
            if (!(e.name in acc)) acc[e.name] = [];
            acc[e.name].push(item);
            return acc;
        }, {});
    } else {
        p.site_extensions = {};
    }
    if (p.user_extensions && p.user_extensions.length > 0) {
        const userExtVersions = uidsToObjects(storeState, 'version', p.user_extensions, {
            keepOrder: true, packed: true });
        p.user_extensions = userExtVersions.reduce((acc, ver, i) => {
            const e = uidsToObjects(storeState, 'extension', [ver.extension.item], { single: true });
            const u = storeState.user.users.find((u) => u.login === p.user_extensions[i].rel.login);
            const item = { user: u.login, name: u.name, version: ver.uid };
            if (!(e.name in acc)) acc[e.name] = [];
            acc[e.name].push(item);
            return acc;
        }, {});
    } else {
        p.user_extensions = {};
    }

    return p;
};
export const projectsFromApi = (p, storeState) => p.map((x) => projectFromApi(x, storeState));

export const projectToApi = (p) => {
    p.env = p.env ? envToApi(p.env) : '';
    p.site_env = p.site_env ? envToApi(p.site_env) : '';
    p.user_env = p.user_env ? envToApi(p.user_env) : '';
    p.mongo_url = getMongoUrlPrefix(p, false) + p.mongo_url;

    // ensure ports are numbers
    if (typeof p.redis_port === 'string')
      p.redis_port = parseInt(p.redis_port);
    if (typeof p.redis_db === 'string')
      p.redis_db = parseInt(p.redis_db);

    delete p.sites;
    delete p.project_extensions;
    delete p.site_extensions;
    delete p.user_extensions;
    return p;
};
export const projectsToApi = (p) => p.map((x) => projectToApi(x));

// SITE
export const siteFromApi = (s) => {
    return s;
};
export const sitesFromApi = (s) => s.map((x) => siteFromApi(x));

export const siteToApi = (s) => {
    delete s.projects;
    return s;
};
export const sitesToApi = (s) => s.map((x) => siteToApi(x));

// VERSION
export const versionFromApi = (v) => {
    v.env = envFromApi(v.env, '[]');
    return v;
};
export const versionsFromApi = (v) => v.map((x) => versionFromApi(x));

export const versionToApi = (v) => {
    v.env = envToApi(v.env);
    delete v.extension;
    return v;
};
export const versionsToApi = (v) => v.map((x) => versionToApi(x));

// USER
export const userFromApi = (u) => {
    return u;
};
export const usersFromApi = (u) => u.map((x) => userFromApi(x));

export const userToApi = (u) => {
    delete u.projects;
    delete u.sites;
    return u;
};
export const usersToApi = (u) => u.map((x) => userToApi(x));
