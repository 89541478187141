<template>
  <v-dialog :value="open" max-width="50vw" scrollable @click:outside="$emit('close')" @keydown.esc="$emit('close')">
    <v-card>
      <v-card-title>
        <span class="text-h5">New extension</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-form ref="form" v-model="formIsValid">
            <div class="attributes-block">
              <div class="attributes-item">
                <label>Name</label>
                <v-text-field
                  v-model="name"
                  :rules="[rules.required, rules.nameNotUsed]"
                  dense
                ></v-text-field>
              </div>
              <div class="attributes-item">
                <label>Description</label>
                <v-textarea
                  v-model="description"
                  auto-grow
                  dense
                  :rows="2"
                ></v-textarea>
              </div>
              <div class="attributes-item">
                <label>Categories</label>
                <v-combobox
                  v-model="categories"
                  :items="allCategories"
                  multiple
                  hide-selected
                  persistent-hint
                  hint="Press <Enter> to add a custom category"
                  small-chips
                >
                </v-combobox>
              </div>
            </div>
          </v-form>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="accent"
          text
          @click="$emit('close')"
        >
          Cancel
        </v-btn>
        <v-btn
          color="primary"
          text
          :disabled="!formIsValid"
          @click="createExtension"
        >
          Create
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex';
import rules from '@/helpers/rules.js';
import { extension as extensionDefaults } from '@/helpers/models/defaults.js';

export default {
  name: 'NewExtensionModal',
  props: {
    open: { type: Boolean, required: true },
  },
  data() {
    return {
      formIsValid: false,
      rules: {
        ...rules,
        nameNotUsed: (value) => !this.usedExtensionNames.includes(value) || 'Name already taken',
      },
      defaultCategories: extensionDefaults.categories,

      name: '',
      description: extensionDefaults.defaults.description,
      categories: extensionDefaults.defaults.categories,
    };
  },
  computed: {
    ...mapState({
      extensions: (state) => state.extension.extensions,
    }),
    allCategories() {
      const categories = this.$store.getters['extension/getAllCategories']();
      for (const cat of extensionDefaults.categories) {
        if (!categories.includes(cat)) {
          categories.push(cat);
        }
      }
      return categories;
    },
    usedExtensionNames() {
      return this.extensions.map((e) => e.name);
    },
  },
  methods: {
    createExtension() {
      const data = {
        name: this.name,
        description: this.description,
        categories: this.categories,
      };
      this.$emit('create', data);

      // reset modal
      this.name = '';
      this.description = extensionDefaults.defaults.description;
      this.categories = extensionDefaults.defaults.categories;
      this.$refs.form.resetValidation();
    },
  },
};
</script>

<style scoped>
.attributes-block {
  display: grid;
  grid-template-columns: 1fr 2fr;
  padding-right: 2rem;
  align-items: center;
}

.attributes-item {
  display: contents;
}

.attributes-item > label {
  font-weight: 500;
  font-size: 0.9rem;
  text-transform: capitalize;
  margin-left: 2rem;
}
</style>
