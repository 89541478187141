<template>
  <div class="main-wrapper pa-8">
    <NewSiteModal
      :open="showNewModal"
      @close="showNewModal = false"
      @create="create"
    ></NewSiteModal>

    <EntitiesListTemplate
      :items="sites"
      title="Sites"
      type="site"
      full-height
    >
      <template #before-list-search>
        <v-btn v-if="isAdmin" class="mr-4" :color="`${color} lighten-3`" @click="showNewModal = true">
          create
        </v-btn>
      </template>

      <template v-slot="item">
        <v-icon class="mr-4">{{ icons.site[item.type] }}</v-icon>
        <entity-label :item="item" type="Site"></entity-label>
      </template>
    </EntitiesListTemplate>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import icons from '@/helpers/icons.js';
import colors from '@/helpers/colors.js';

import EntitiesListTemplate from '@/views/EntitiesListTemplate.vue';
import NewSiteModal from '@/components/NewSiteModal.vue';

export default {
  name: 'Sites',
  components: { EntitiesListTemplate, NewSiteModal },
  data() {
    return {
      showNewModal: false,
    };
  },
  computed: {
    ...mapState({
      sites: (state) => state.site.sites,
    }),
    icons() { return icons; },
    color() { return colors.site },
    isAdmin() {
      return this.$store.getters['user/isAdmin']();
    },
  },
  methods: {
    async create(data) {
      try {
        const newEntity = await this.$store.dispatch('site/create', { data });
        this.$router.push({ name: 'site', params: { id: newEntity.uid } });
      } finally {
        this.showNewModal = false;
      }
    },
  },
};
</script>
