import Vue from 'vue';
import Vuex from 'vuex';
import createLogger from 'vuex/dist/logger';

import common from './modules/common.js';
import extension from './modules/extension.js';
import global from './modules/global.js';
import project from './modules/project.js';
import site from './modules/site.js';
import user from './modules/user.js';
import version from './modules/version.js';

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== 'production';
const plugins = [];
if (debug) {
  plugins.push(createLogger());
}

export default new Vuex.Store({
  modules: {
    common,
    extension,
    global,
    project,
    site,
    user,
    version,
  },
  strict: debug,
  plugins
});
