export default {
    required: value => !!(value.toString()) || 'Required',
    counter5: value => value.toString().length <= 5 || 'Max 5 digits',
    url: value => {
      if (value === '') return true;
      const pattern = /^(https?)?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}(:[0-9]{4,5})?(\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*))?$/;
      return pattern.test(value) || 'Invalid URL';
    },
    semver: value => {
      const pattern = /^[0-9]+\.[0-9]+\.[0-9a-z]+$/;
      return pattern.test(value) || 'Invalid semantic version';
    },
    email: value => {
      const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return pattern.test(value) || 'Invalid email';
    },
};
