import axios from 'axios';
import cloneDeep from 'lodash.clonedeep';

import { makeEntityStore } from '@/store/base-entity.js';
import { versionToApi, versionsFromApi } from '@/helpers/models/formatters.js';

export default makeEntityStore('extension', {
  getters: {
    getExtension: (state) => (version) => {
      return state.extensions.find((e) =>
        e.versions.findIndex((v) => v.item === version) !== -1);
    },
    getVersions: (state, _, rootState) => (uid) => {
      if (state.extensions.length === 0 || rootState.version.versions.length === 0)
        return [];
      const ext = state.extensions.find((e) => e.uid === uid);
      const vUids = ext.versions.map((i) => i.item);
      return rootState.version.versions.filter((v) => vUids.includes(v.uid));
    },
    getAllCategories: (state) => () => {
      if (state.extensions.length === 0) return [];
      const c = state.extensions.reduce((acc, e) => {
        return [ ...acc, ...e.categories.filter((c) => !acc.includes(c)) ];
      }, []);
      c.sort();
      return c;
    },
    isUnused: (state, _, rootState) => (uid) => {
      const { projects } = rootState.project;
      return !projects.some((p) => {
        return p.project_extensions.some((e) => e.uid === uid);
      });
    },
  },

  postprocessCreate: ({ commit }, data) => {
    const { extension, versions } = data;
    commit('version/addVersions', { versions: versionsFromApi(versions) }, { root: true });
    return extension;
  },

  beforeSave: async({ commit, rootState }, data) => {
    const versions = rootState.version.currentVersion;
    const refVersions = rootState.version.versionBackup;

    // get versions to add / update / remove
    const versionsToAdd = [];
    const versionsToUpdate = [];
    const versionsToRemove = [];
    for (const version of versions) {
      if (version.name === '<disabled>') continue;
      const toAdd = refVersions.findIndex((v) => v.uid === version.uid) === -1;
      const versionForApi = versionToApi(cloneDeep(version));
      if (toAdd) {
        versionForApi.extension_uid = data.uid;
        versionsToAdd.push(versionForApi);
      } else {
        versionsToUpdate.push(versionForApi);
      }
    }
    for (const version of refVersions) {
      const toRemove = versions.findIndex((v) => v.uid === version.uid) === -1;
      if (toRemove) versionsToRemove.push(version.uid);
    }

    // update versions:
    const res = await Promise.all(
      // . add the new items
      versionsToAdd.map((v) => axios.post('/versions', v))
      // . update the existing items
      .concat(versionsToUpdate.map((v) => axios.patch(`/versions/${v.uid}`, v)))
      // . remove the superfluous items
      .concat(versionsToRemove.map((uid) => axios.delete(`/versions/${uid}`)))
    );

    commit('version/pushVersions', {
      versions: versionsFromApi(res.slice(0, versionsToAdd.length).map((d) => d.data)),
      pushToCurrent: true,
    }, { root: true });
    commit('version/updVersions', {
      versions: versionsFromApi(res.slice(versionsToAdd.length, versionsToAdd.length + versionsToUpdate.length).map((d) => d.data)),
      pushToCurrent: true,
    }, { root: true });
    commit('version/delVersions', {
      versions: versionsToRemove,
      pushToCurrent: true,
    }, { root: true });

    // relink default version
    if (data.currentDefaultVersionUid !== data.defaultVersionUid) {
      await axios.patch('/entity-relink', {
        source_type: 'Extension',
        source_uid: data.uid,
        target_type: 'Version',
        link_type: 'HAS_DEFAULT',
        link_dir: 'from',
        target_old_uids: [data.currentDefaultVersionUid],
        target_new_uids: [data.defaultVersionUid],
        options: { reconnectProjects: true }
      });
    }

    return data;
  },

  afterSave: ({ commit }) => {
    commit('version/cleanChanges', null, { root: true });
  },

  beforeDuplicate: (_, duplicate) => {
    duplicate.default_version_uid = duplicate.defaultVersionUid;
    return duplicate;
  },

  postprocessDuplicate: ({ commit }, duplicate) => {
    let { extension, versions } = duplicate;
    versions = versionsFromApi(versions);
    commit('version/addVersions', { versions }, { root: true });
    return extension;
  },

  afterDelete: async ({ commit, state, rootState }, delUid) => {
    const e = state.extensions.find((x) => x.uid === delUid);

    const projects = cloneDeep(rootState.project.projects);
    projects.forEach((p) => {
      p.project_extensions = p.project_extensions.filter(
        (ext) => ext.uid !== e.uid);
      if (e.name in p.site_extensions)
        delete p.site_extensions[e.name];
      if (e.name in p.user_extensions)
        delete p.user_extensions[e.name];
    });
    commit('project/setProjects', { projects }, { root: true });
    commit('version/removeVersions', { uids: e.versions.map((i) => i.item) }, { root: true });
    commit('setExtensions', { extensions: state.extensions.filter((x) => x.uid !== delUid) });
  },

});
