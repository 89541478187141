<template>
  <div class="mb-4">

    <template v-if="rel">
      <v-dialog
        v-for="entityType in modifiableSearchedTypes"
        :key="`${entityType}-${popupRefreshKey}`"
        :value="showPopups[entityType]"
        @update="closePopup(entityType)"
        :max-width="entityType === 'Extension' ? '80%' : '800px'"
      >
        <v-card v-if="rel[entityType]">
          <v-card-title>
            <span class="text-h5 mb-8">{{ searchedTypes[entityType].title }}</span>
          </v-card-title>
          <v-card-text>
            <v-text-field
              :value="popupSearch[entityType]"
              @input="(v) => setPopupSearch(entityType, v)"
              placeholder="Filter..."
              prepend-inner-icon="mdi-magnify"
              outlined
              clearable
              dense
              hide-details
            ></v-text-field>

            <div class="popup-list" :class="{ 'scrollable': filteredEntities[entityType].length > 10 }">
              <template v-if="entityType === 'Extension'">
                <div class="popup-items">
                  <div v-for="item in filteredEntities[entityType]" :key="item.key" style="display: contents;">
                    <v-checkbox
                      :input-value="rel[entityType].items"
                      @change="(v) => updateRel(entityType, item.key, v)"
                      :value="item.key"
                      :label="item.text"
                      hide-details
                    ></v-checkbox>
                    <v-select
                      class="ml-4"
                      :items="versionItems[item.key]"
                      :value="rel[entityType].extra[item.key]?.uid"
                      item-value="key"
                      :disabled="rel[entityType].items && !rel[entityType].items.includes(item.key)"
                      @input="(value) => setSelectedExtensionVersion(item.key, value)"
                      dense
                      hide-details
                    ></v-select>
                  </div>
                </div>
              </template>
              <template v-else>
                <div v-for="item in filteredEntities[entityType]" :key="item.key">
                  <v-checkbox
                    :input-value="rel[entityType].items"
                    @change="(v) => updateRel(entityType, item.key, v)"
                    :value="item.key"
                    hide-details
                  >
                    <template #label>
                      <entity-label :item="item" :type="entityType"></entity-label>
                    </template>
                  </v-checkbox>
                </div>
              </template>
            </div>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="accent"
              text
              @click="closePopup(entityType)"
            >
              Cancel
            </v-btn>
            <v-btn
              color="primary"
              text
              @click="submitPopup(entityType)"
            >
              Set
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>

    <div v-if="loading || !rel" class="text-center">
      <v-progress-circular
        indeterminate
        size="64"
      ></v-progress-circular>
    </div>

    <v-row v-else>
      <v-col v-for="entityType in Object.keys(searchedTypes)" :key="entityType" cols="12" sm="4">
        <v-card elevation="2" class="entity-relations-card mx-auto">
          <v-card-title
            class="py-2 amber lighten-2"
            :style="{ cursor: 'pointer' }"
          >
            <v-icon class="mr-2">{{ typeIcons[entityType] }}</v-icon>
            {{ searchedTypes[entityType].title }}
          </v-card-title>
          <v-card-text>
            <v-list>
              <v-list-item v-for="item in filteredData[entityType]" :key="item.key" :to="item.link" >
                <v-list-item-content>
                  <v-list-item-title>
                    <entity-label :item="item" :type="entityType"></entity-label>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card-text>
          <v-card-actions>
            <template v-if="searchedTypes[entityType].modifiable">
              <v-btn
                color="primary"
                text
                @click="setPopup(entityType, true)"
                class="mr-4"
              >
                Modify
              </v-btn>
              <v-spacer></v-spacer>
            </template>
            <v-text-field
              :value="search[entityType]"
              @input="(v) => onSearch(entityType, v)"
              placeholder="Filter..."
              append-icon="mdi-magnify"
              outlined
              clearable
              dense
              hide-details
              single-line
            ></v-text-field>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import icons from '@/helpers/icons.js';
import cloneDeep from 'lodash.clonedeep';
import relations from '@/helpers/models/relations.js';
import { computeEntityDifference } from '@/helpers/misc.js';

export default {
  name: 'RelatedEntitiesLists',
  props: {
    type: { type: String, required: true },
    entityUid: { type: String, default: '' },
    searchedTypes: { type: Object, default: () => ({}) },
    filterArchived: { type: Boolean, default: true },
    extra: { type: Object, default: () => ({}) },
  },
  data() {
    return {
      loading: true,

      rel: {},
      relCurrent: null,

      search: {},
      showPopups: {},
      popupRefreshKey: 0,
      popupSearch: {},

      typeIcons: icons.types,
    };
  },
  computed: {
    ...mapState({
      showArchived: (state) => state.global.showArchived,
    }),
    lowerType() { return this.type.toLowerCase(); },
    allEntities() {
      if (!this.relCurrent) return {};
      const $this = this;
      return Object.keys(this.searchedTypes).reduce((acc, t) => {
        const x = t.toLowerCase();
        acc[t] = $this.$store.state[x][`${x}s`].map((i) => {
          const d = {
            key: i.uid,
            text: i.name,
            link: { name: x, params: { id: i.uid } },
            is_archived: i.is_archived,
            is_enabled: i.is_enabled,
          };
          if (t === 'Extension') {
            d.version = $this.relCurrent.Extension.extra[i.uid];
          }
          return d;
        });
        return acc;
      }, {});
    },
    filteredEntities() {
      const $this = this;
      return Object.keys(this.allEntities).reduce((acc, t) => {
        const s = $this.popupSearch[t].toLowerCase();
        return { ...acc, [t]: $this.allEntities[t].filter(
          (i) => i.text.toLowerCase().indexOf(s) !== -1) };
      }, {});
    },
    filteredData() {
      if (this.loading || !this.relCurrent) return {};
      const filterArchived = this.filterArchived && !this.showArchived;
      const search = this.search;
      return Object.keys(this.allEntities).reduce((acc, t) => {
        let items = this.allEntities[t].filter((i) => this.relCurrent[t].items.includes(i.key));
        if (search[t]) {
          const s = search[t].toLowerCase();
          items = items.filter((i) => i.text.toLowerCase().indexOf(s) !== -1)
        }
        if (filterArchived)
          items = items.filter((i) => !i.is_archived)
        return { ...acc, [t]: items };
      }, {});
    },
    modifiableSearchedTypes() {
      const t = [];
      for (const [k, v] of Object.entries(this.searchedTypes)) {
        if (v.modifiable) t.push(k);
      }
      return t;
    },
    versionItems() {
      if (this.loading) return {};
      const $this = this;
      return this.rel.Extension.items.reduce((acc, uid) => {
        const versions = $this.$store.getters['extension/getVersions'](uid);
        return { ...acc, [uid]: [
          { key: '__default', text: '<default>' },
          ...versions.map((v) => ({ key: v.uid, text: v.name })),
        ] };
      }, {});
    },
    selectedExtensionVersions() {
      if (this.loading) return {};
      const $this = this;
      return this.rel.Extension.reduce((acc, uid) => {
        return { ...acc, [uid]: $this.extra.Extension[uid].uid };
      }, {});
    },
    relationIcons() {
      return Object.keys(this.searchedTypes).reduce((acc, t) => {
        if (this.type in relations && t in relations[this.type])
          acc[t] = 'mdi-chevron-' + (relations[this.type][t].relFrom ? 'right' : 'left');
        else
          acc[t] = 'mdi-circle-small';
        return acc;
      }, {});
    },
  },
  methods: {
    async load() {
      this.loading = true;
      const data = await this.$store.dispatch('common/getEntityNeighbors', {
        type: this.type, uid: this.entityUid, searchedTypes: Object.keys(this.searchedTypes)
      });

      this.search = {};
      this.showPopups = {};
      const rel = {};
      for (const type in data.relations) {
        const t = type.toLowerCase();
        const items = data.relations[type].map((i) => ({
          key: i.uid,
          text: i.name,
          link: { name: t, params: { id: i.uid } }
        }));
        items.sort((i1, i2) => i1.text > i2.text);
        this.search[type] = '';
        this.showPopups[type] = false;

        if (!(type in rel)) rel[type] = {};
        rel[type].items = items.map((i) => i.key);
        rel[type].extra = this.extra[type];
      }
      this.rel = cloneDeep(rel);
      this.relCurrent = cloneDeep(rel);

      const $this = this;
      setTimeout(() => {
        $this.$store.commit(`${$this.lowerType}/setCurrentRelations`, { relations: rel });
        $this.loading = false;
      }, 100);
    },
    onSearch(t, v) {
      this.search = { ...this.search, [t]: v || '' };
    },
    updateRel(e, k, v) {
      const r = cloneDeep(this.rel[e].items);
      if (v && !r.includes(k)) {
        r.push(k);
        if (e === 'Extension') {
          this.$set(this.rel[e].extra, k, {
            overriden: false,
            link: true,
            type: 'Version',
            uid: '__default'
          });
        }
      }
      else if (v && r.includes(k)) {
        r.splice(r.findIndex((x) => x === k), 1);
      }
      this.$set(this.rel[e], 'items', r);
    },
    restoreRelationsFromBackup() {
      this.relCurrent = this.$store.getters[`${this.lowerType}/getBackupRelations`]();
    },
    setPopup(p, on) {
      this.showPopups = { ...this.showPopups, [p]: on };
    },
    closePopup(p) {
      this.showPopups = { ...this.showPopups, [p]: false };
      this.$set(this.rel, p, this.relCurrent[p]);
    },
    submitPopup(p) {
      this.showPopups = { ...this.showPopups, [p]: false };
      const diff = computeEntityDifference(this.relCurrent[p].items, this.rel[p].items);

      if (p === 'Extension') {
        for (const key of this.rel[p].items) {
          if (key in this.relCurrent[p].extra) {
            const oldVersion = this.relCurrent[p].extra[key].uid;
            const newVersion = this.rel[p].extra[key].uid;
            if (oldVersion !== newVersion) {
              this.$store.commit('project/updateExtensionVersion', {
                name: this.$store.state.extension.extensions.find((ext) => ext.uid === key).name,
                version: newVersion,
                env: [],
              })
            }
          }
        }
      }

      if (diff.toAdd.length > 0)
        this.$store.dispatch(`${this.lowerType}/addRelationsToCurrent`,
          { entityType: p, relations: diff.toAdd.map((uid) => ({
            item: uid, extra: { [uid]: (this.rel[p]?.extra || {})[uid] }
          })) });
      if (diff.toDel.length > 0)
        this.$store.dispatch(`${this.lowerType}/removeRelationsFromCurrent`,
          { entityType: p, relations: diff.toDel });
      
      const $this = this;
      this.$nextTick(() => {
        $this.popupRefreshKey++;
        this.relCurrent = cloneDeep(this.rel);

        // manually re-check for exiting overrides for this extension
        if (p === 'Extension') {
          for (const extUid of this.relCurrent.Extension.items) {
            const { state: st } = this.$store;
            const extName = st.extension.extensions.find((e) => e.uid === extUid).name;
            this.relCurrent.Extension.extra[extUid].overriden = (
              extName in st.project.currentProject.site_extensions ||
              extName in st.project.currentProject.user_extensions
            );
          }
        }
      });
    },
    setPopupSearch(p, v) {
      this.$set(this.popupSearch, p, v);
    },

    // specific methods for Project > Extension relationship
    setSelectedExtensionVersion(extensionUid, versionUid) {
      this.$set(this.rel.Extension.extra[extensionUid], 'uid', versionUid);
    },
  },
  created() {
    this.load();
    this.popupSearch = Object.keys(this.searchedTypes).reduce((acc, key) => ({ ...acc, [key]: '' }), {});
  },
};
</script>

<style scoped>
.entity-relations-card {
  height: 290px;
  display: grid;
  grid-template-rows: auto 1fr auto;
}

.entity-relations-card:deep(.v-card__text) {
  overflow-y: auto;
  padding-bottom: 0 !important;
}

.popup-list {
  max-height: 450px;
  overflow-x: hidden;
}
.popup-list:not(.scrollable) {
  overflow-y: hidden;
}
.popup-list.scrollable {
  overflow-y: auto;
}

.popup-items {
  display: grid;
  grid-template-columns: 2fr 1fr;
  align-items: center;
  column-gap: 16px;
}
</style>
