<template>
  <EntityTemplate
    ref="content"
    v-if="currentProject && selectedSite"
    :listed-entities="projectsInSelectedSite"
    :entity="currentProject"
    :data-is-dirty="dataIsDirty"
    :related-extra="{ Extension: currentExtensionVersions }"
    :reload-fn="reload"
    :name="currentProject.name"
    type="project"
    :can-create="isAdmin"
    :can-duplicate="isAdmin"
    :can-delete="isAdmin"
    create-modal-component="NewProjectModal"
    sidebar-title="Projects"
    create-btn-label="Create project"
    :open-attributes="openAttributes"
    @set-open-attributes="(v) => openAttributes = v"
    @add-rel="addRel"
    @del-rel="delRel"
    @upd-extra="updExtra"
  >
    <template #sidebar-pre>
      <v-select
        :items="siteItems"
        :value="selectedSite"
        @change="setSelectedSite"
        dense
        hide-details
        single-line
        outlined
        :style="{ maxWidth: '20vw' }"
        color="primary"
        class="my-2 px-2"
      >
        <template v-slot:selection="data">
          <v-icon class="mr-2">{{ siteIcons[data.item.type] }}</v-icon>
          <span>{{ data.item.text }}</span>
        </template>
        <template v-slot:item="data">
          <v-list-item-icon><v-icon>{{ siteIcons[data.item.type] }}</v-icon></v-list-item-icon>
          <v-list-item-content>
            {{ data.item.text }}
          </v-list-item-content>
        </template>
      </v-select>
    </template>

    <template>
      <NewOverridePopup
        :open="showNewOverridePopup"
        :type="overridePopupType"
        :items="overrideItems"
        :item-value="overridePopupType === 'site' ? 'uid' : 'login'"
        :on-submit="addEnvOverride"
        @update-popup="(on) => showNewOverridePopup = on"
      />
      <DeleteOverridePopup
        :open="showDeleteOverridePopup"
        @delete="removeEnvOverride"
        @update-popup="(on) => showDeleteOverridePopup = on"
      ></DeleteOverridePopup>

      <AttributesPanel
        title="Global"
        :id="projectAnchors[0].name"
        :data="currentProject"
        :backup="projectBackup"
        :attributes="[
          { name: 'name', title: 'Name', component: 'v-text-field', rules: [rules.required] },
          { name: 'description', title: 'Description', component: 'v-textarea', extra: { 'auto-grow': true, rows: 2 } },
          { name: 'kitsu_url', title: 'Kitsu URL', component: 'v-text-url', rules: [rules.url] },
          { name: 'wiki_url', title: 'Wiki URL', component: 'v-text-url', rules: [rules.url] },
          { name: 'libreflow', title: 'Libreflow version', component: 'v-text-field', rules: [] },
        ]"
        @update="({ attr, value }) => $refs.content.updateAttribute(attr, value)"
      />

      <AttributesPanel
        title="Redis"
        :id="projectAnchors[1].name"
        :data="currentProject"
        :backup="projectBackup"
        :attributes="[
          { name: 'redis_url', title: 'URL', component: 'v-text-url', rules: [rules.required] },
          { name: 'redis_password', title: 'Password', component: 'v-text-field', rules: [rules.required], extra: {
            type: showRedisPassword ? 'text' : 'password',
            'append-icon': showRedisPassword ? 'mdi-eye' : 'mdi-eye-off',
          }, onClick: 'toggle-password' },
          { name: 'redis_port', title: 'Port', component: 'v-text-field', rules: [rules.required, rules.counter5], extra: {
            type: 'number', maxlength: 5, counter: true,
          } },
          { name: 'redis_cluster', title: 'Cluster', component: 'v-text-field', rules: [rules.required] },
          { name: 'redis_db', title: 'DB', component: 'v-select', rules: [rules.required], extra: {
            items: Array.from(Array(16).keys()).map((i) => ({
              value: i,
              text: i,
            }))
          } },
        ]"
        @update="({ attr, value }) => $refs.content.updateAttribute(attr, value)"
        @toggle-password="showRedisPassword = !showRedisPassword"
      />

      <AttributesPanel
        title="Mongo"
        :id="projectAnchors[2].name"
        :data="currentProject"
        :backup="projectBackup"
        :attributes="[
          { name: 'mongo_url', title: 'URL', component: 'v-text-url', rules: [rules.required],
            extra: { prefix: mongoUrlPrefix } },
          { name: 'mongo_user', title: 'User', component: 'v-text-field', rules: [rules.required] },
          { name: 'mongo_password', title: 'Password', component: 'v-text-field', rules: [rules.required], extra: {
            type: showMongoPassword ? 'text' : 'password',
            'append-icon': showMongoPassword ? 'mdi-eye' : 'mdi-eye-off',
          }, onClick: 'toggle-password' },
        ]"
        @update="({ attr, value }) => $refs.content.updateAttribute(attr, value)"
        @toggle-password="showMongoPassword = !showMongoPassword"
      />

      <AttributesPanel
        title="Exchange server"
        :id="projectAnchors[3].name"
        :data="currentProject"
        :backup="projectBackup"
        :attributes="[
          { name: 'exchange_server_type', title: 'Type', component: 'v-select', rules: [rules.required], extra: {
            items: [
              { value: 'minio', text: 'minio' },
            ]
          } },
          { name: 'exchange_server_url', title: 'URL', component: 'v-text-url', rules: [rules.required] },
          { name: 'exchange_server_login', title: 'Login', component: 'v-text-field', rules: [rules.required] },
          { name: 'exchange_server_password', title: 'Password', component: 'v-text-field', rules: [rules.required], extra: {
            type: showExchangeServerPassword ? 'text' : 'password',
            'append-icon': showExchangeServerPassword ? 'mdi-eye' : 'mdi-eye-off',
          }, onClick: 'toggle-password' },
          { name: 'exchange_server_bucket', title: 'Bucket', component: 'v-text-field', rules: [rules.required] },
        ]"
        @update="({ attr, value }) => $refs.content.updateAttribute(attr, value)"
        @toggle-password="showExchangeServerPassword = !showExchangeServerPassword"
      />

      <v-expansion-panel :id="projectAnchors[4].name">
        <v-expansion-panel-header>Environment variables</v-expansion-panel-header>
        <v-expansion-panel-content>
          <EnvVarsBlock
            :items="currentProject.env"
            :backup-items="projectBackup.env"
            block-id="project-env-vars"
            add-label="Add variable"
            @update-key="({ oldKey, key, value }) => updateEnvVarKey('project', oldKey, key, value)"
            @update-value="({ key, value }) => updateEnvVarValue('project', key, value)"
            @remove="(index) => removeEnvVar('project', index)"
            @add="addEnvVar('project')" />
          <h4>Per site</h4>
          <EnvVarsWrapperBlock
            :items="envSiteItems"
            :can-add="envVarAvailableSites.length > 0"
            block-id="site-list-env-vars"
            add-label="Add site"
            @update="({ key, value }) => updateEnvVarOverride('site', key, value)"
            @add="openNewOverridePopup('site')"
            @remove="(value) => openDeleteOverridePopup('site', value)"
          >
            <template v-slot:default="slotProps">
              <v-alert
                border="left"
                color="primary lighten-1"
                class="ml-4 py-2"
                colored-border
                :elevation="1"
              >
                <EnvVarsBlock
                  :items="currentProject.site_env[slotProps.item] || []"
                  :backup-items="projectBackup.site_env[slotProps.item] || []"
                  block-id="site-env-vars"
                  add-label="Add variable"
                  @update-key="({ oldKey, key, value }) => updateEnvVarKey('site', oldKey, key, value, slotProps.item)"
                  @update-value="({ key, value }) => updateEnvVarValue('site', key, value, slotProps.item)"
                  @remove="(index) => removeEnvVar('site', index, slotProps.item)"
                  @add="addEnvVar('site', slotProps.item)" />
              </v-alert>
            </template>
          </EnvVarsWrapperBlock>
          <h4>Per user</h4>
          <EnvVarsWrapperBlock
            :items="envUserItems"
            :can-add="envVarAvailableUsers.length > 0"
            block-id="user-list-env-vars"
            add-label="Add user"
            @update="({ key, value }) => updateEnvVarOverride('user', key, value)"
            @add="openNewOverridePopup('user')"
            @remove="(value) => openDeleteOverridePopup('user', value)"
          >
            <template v-slot:default="slotProps">
              <v-alert
                border="left"
                color="primary lighten-1"
                class="ml-4 py-2"
                colored-border
                :elevation="1"
              >
                <EnvVarsBlock
                  class="ml-4"
                  :items="currentProject.user_env[slotProps.item] || []"
                  :backup-items="projectBackup.user_env[slotProps.item] || []"
                  block-id="user-env-vars"
                  add-label="Add variable"
                  @update-key="({ oldKey, key, value }) => updateEnvVarKey('user', oldKey, key, value, slotProps.item)"
                  @update-value="({ key, value }) => updateEnvVarValue('user', key, value, slotProps.item)"
                  @remove="(index) => removeEnvVar('user', index, slotProps.item)"
                  @add="addEnvVar('user', slotProps.item)" />
              </v-alert>
            </template>
          </EnvVarsWrapperBlock>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel :id="projectAnchors[5].name">
        <v-expansion-panel-header>Extensions</v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row no-gutters align="center" class="mb-4">
            <v-btn
              color="primary lighten-2 black--text"
              @click="$refs.content.openRelatedEntitiesPopup('Extension')"
              class="mr-4"
            >
              Modify
            </v-btn>
            <v-text-field
              v-model="extensionSearch"
              placeholder="Find extension..."
              prepend-inner-icon="mdi-magnify"
              outlined
              clearable
              dense
              hide-details
            ></v-text-field>
          </v-row>

          <ExtensionDisplay
            v-for="item, i in filteredProjectExtensions"
            :key="`list-extensions-${i}`"
            :extension="item"
            :available-extensions="availableExtensions"
          ></ExtensionDisplay>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </template>
  </EntityTemplate>
</template>

<script>
import { mapState } from 'vuex';
import { uidsToObjects, getMongoUrlPrefix } from '@/helpers/misc.js';
import rules from '@/helpers/rules.js';
import icons from '@/helpers/icons.js';

import EntityTemplate from '@/views/EntityTemplate.vue';
import AttributesPanel from '@/components/AttributesPanel.vue';
import DeleteOverridePopup from '@/components/DeleteOverridePopup.vue';
import EnvVarsBlock from '@/components/EnvVarsBlock.vue';
import EnvVarsWrapperBlock from '@/components/EnvVarsWrapperBlock.vue';
import ExtensionDisplay from '@/components/ExtensionDisplay.vue';
import NewOverridePopup from '@/components/NewOverridePopup.vue';

export default {
  name: 'Project',
  components: {
    EntityTemplate,
    AttributesPanel,
    DeleteOverridePopup,
    EnvVarsBlock,
    EnvVarsWrapperBlock,
    ExtensionDisplay,
    NewOverridePopup,
  },
  data() {
    return {
      openAttributes: [],
      rules,
      showRedisPassword: false,
      showMongoPassword: false,
      showExchangeServerPassword: false,

      extensionSearch: '',

      showNewProjectModal: false,

      showConfirmationPopup: false,

      overridePopupType: 'site',
      overrideItems: [],
      overrideValue: '',
      showNewOverridePopup: false,
      showDeleteOverridePopup: false,

      nextSite: null,
      nextProject: null,
    };
  },
  computed: {
    ...mapState({
      users: (state) => state.user.users,
      user: (state) => state.user.user,
      extensions: (state) => state.extension.extensions,
      projects: (state) => state.project.projects,
      sites: (state) => state.site.sites,
      versions: (state) => state.version.versions,
      currentProject: (state) => state.project.currentProject,
      projectBackup: (state) => state.project.projectBackup,
      selectedSite: (state) => state.project.selectedSite,
      dataIsDirty: (state) => state.project.dataIsDirty,
    }),
    isAdmin() {
      return this.$store.getters['user/isAdmin']();
    },
    extensionTypes() {
      return ['project', 'site', 'user'];
    },
    siteIcons() {
      return icons.site;
    },
    siteItems() {
      if (!this.user) return [];
      return this.sites.map((site) => ({
        text: site.name || site.code,
        value: site.uid,
        type: site.type,
      }));
    },
    userItems() {
      return this.users.map((user) => ({
        text: user.name || user.login,
        value: user.login,
      }));
    },
    extensionItems() {
      return this.extensions.map((ext) => ({
        text: ext.name,
        value: ext.name,
      }));
    },
    envSiteItems() {
      const $this = this;
      return this.siteItems.filter((i) => {
        return i.value in $this.currentProject.site_env;
      });
    },
    envUserItems() {
      const $this = this;
      return this.userItems.filter((i) => {
        return i.value in $this.currentProject.user_env;
      });
    },
    currentExtensionVersions() {
      if (!this.currentProject) return {};
      const $this = this;
      return this.currentProject.project_extensions.reduce((acc, e) => {
        return { ...acc, [e.uid]: {
          uid: e.version,
          type: 'Version',
          link: true,
          overriden: (
            e.extension in $this.currentProject.site_extensions ||
            e.extension in $this.currentProject.user_extensions
          )
        } };
      }, {});
    },
    projectsInSelectedSite() {
      if (!this.selectedSite) return [];
      const site = this.sites.find((s) => s.uid === this.selectedSite);
      return uidsToObjects(this.$store.state, 'project', site.projects, { packed: true });
    },
    filteredProjectExtensions() {
      if (!this.extensionSearch) return this.currentProject.project_extensions;
      const s = this.extensionSearch;
      return this.currentProject.project_extensions.filter((e) => {
        return e.extension.toLowerCase().includes(s);
      });
    },
    envVarAvailableSites() {
      return this.sites.filter((s) => !(s.uid in this.currentProject.site_env));
    },
    envVarAvailableUsers() {
      return this.users.filter((u) => !(u.login in this.currentProject.user_env));
    },
    extensionAvailableSites() {
      if (!this.currentProject) return {};
      const $this = this;
      return Object.keys(this.currentProject.site_extensions).reduce((acc, extName) => {
        const sites = this.sites.filter((s) => {
          return !$this.currentProject.site_extensions[extName].some((i) => i.site === s.uid);
        });
        return { ...acc, [extName]: sites };
      }, {});
    },
    extensionAvailableUsers() {
      if (!this.currentProject) return {};
      const $this = this;
      return Object.keys(this.currentProject.user_extensions).reduce((acc, extName) => {
        const users = this.users.filter((u) => {
          return !$this.currentProject.user_extensions[extName].some((i) => i.user === u.uid);
        });
        return { ...acc, [extName]: users };
      }, {});
    },
    availableExtensions() {
      const $this = this;
      return this.extensions.filter(({ name }) => {
        return $this.currentProject.project_extensions.findIndex((x) => x.extension === name) === -1;
      });
    },
    projectAnchors() {
      return [
        { title: 'Global', name: 'global' },
        { title: 'Redis', name: 'redis' },
        { title: 'Mongo', name: 'mongo' },
        { title: 'Exchange server', name: 'exch-server' },
        { title: 'Environment variables', name: 'env-var' },
        { title: 'Extensions', name: 'extensions' },
      ];
    },
    mongoUrlPrefix() {
      return getMongoUrlPrefix(this.currentProject);
    },
  },
  methods: {
    goToAnchor(anchor, idx) {
      if (!this.openAttributes.includes(idx)) {
        this.openAttributes = [...this.openAttributes, idx];
      }
      setTimeout(() => {        
        const appBarHeight = 64;
        const target = document.getElementById(anchor);
        document.getElementById('main').scrollTo({ top: target.offsetTop - appBarHeight, behavior: 'smooth' });
      }, 100);
    },
    getExtension(versionUid) {
      return this.extensions.find((e) =>
        e.versions.findIndex((v) => v.item === versionUid) !== -1);
    },
    getVersionItems(extName) {
      const ext = this.extensions.find((e) => e.name === extName);
      const vers = uidsToObjects(this.$store.state, 'version', ext.versions, { packed: true });
      return vers.map((v) => ({
        text: v.name,
        value: v.uid,
      }));
    },
    getCurrentVersion(type, extension) {
      const extWithVersions = this.activeExtensions[this.selectedSite.uid][this.currentProject.uid][type];
      if (!extWithVersions) return null;
      return extWithVersions[extension];
    },
    async load() {
      this.$store.commit('global/setInnerLoading', { innerLoading: true });
      await this.prepareData();
    },
    async reload() {
      this.showRedisPassword = false;
      this.showMongoPassword = false;
      this.showExchangeServerPassword = false;

      await this.load();
    },
    async prepareData() {
      if (!this.user) return;
      if (this.extensions.length === 0)
        await this.$store.dispatch('extension/getExtensions');
      if (this.sites.length === 0)
        await this.$store.dispatch('site/getSites');
      if (this.users.length === 0)
        await this.$store.dispatch('user/getUsers');
      if (this.versions.length === 0)
        await this.$store.dispatch('version/getVersions');
      if (this.projects.length === 0)
        await this.$store.dispatch('project/getProjects');

      this.$store.commit('project/setCurrent', {
        currentProject: this.projects.find((p) => p.uid === this.$route.params.id)
      });
      this.$store.commit('project/setSelectedSite', { site: this.sites[0].uid });
      this.openAttributes = [
        0, // base attributes
        // 1, // redis attributes
        // 2, // mongo attributes
        // 3, // exchange server attributes
        4, // env vars
        5, // extensions
      ];

      const $this = this;
      this.$nextTick(() => {
        $this.$refs.content.setRelationData({
          Site: { title: 'In sites', modifiable: true },
          Extension: {
            title: 'Uses extensions',
            modifiable: true,
          },
          User: { title: 'Assigned users', modifiable: true },
        });
      });
      this.$store.commit('global/setInnerLoading', { innerLoading: false });
    },
    async trySetProject(value) {
      if (this.dataIsDirty) {
        this.nextProject = value;
        this.showConfirmationPopup = true;
      } else {
        await this.setProject(value);
      }
    },
    async setProject(value) {
      this.$router.push({ name: 'project', params: { id: value } });

      this.showRedisPassword = false;
      this.showMongoPassword = false;
      this.showExchangeServerPassword = false;

      this.nextSite = null;
      this.nextProject = null;
    },
    setSelectedSite(value) {
      this.$store.commit('project/setSelectedSite', { site: value });
    },
    openNewOverridePopup(type) {
      this.showNewOverridePopup = true;
      this.overridePopupType = type;
      this.overrideItems = (type === 'site')
        ? this.envVarAvailableSites : this.envVarAvailableUsers;
    },
    openDeleteOverridePopup(type, value) {
      this.showDeleteOverridePopup = true;
      this.overridePopupType = type;
      this.overrideValue = value;
    },
    addEnvOverride(value) {
      this.$store.commit('project/addEnvOverride', { type: this.overridePopupType, value });
      this.showNewOverridePopup = false;
    },
    removeEnvOverride() {
      this.$store.commit('project/removeEnvOverride', {
        type: this.overridePopupType,
        value: this.overrideValue
      });
      this.showDeleteOverridePopup = false;
    },
    addEnvVar(type, item) {
      this.$store.commit('project/addEnvVar', { type, item });
    },
    removeEnvVar(type, index, item) {
      this.$store.commit('project/removeEnvVar', { type, index, item });
    },
    updateEnvVarKey(type, oldKey, key, value, item) {
      this.$store.commit('project/updateEnvVarKey', { type, oldKey, key, value, item });
    },
    updateEnvVarValue(type, key, value, item) {
      this.$store.commit('project/updateEnvVarValue', { type, key, value, item });
    },
    updateEnvVarOverride(type, key, value) {
      this.$store.commit('project/updateEnvVarOverride', { type, key, value });
    },

    // special callbacks if updating relations to extensions
    // (to register/unregister matching version data)
    addRel({ entityType, uid }) {
      if (entityType === 'Extension') {
        const v = this.$store.getters['extension/getVersions'](uid)[0]; // arbitrary initialisation
        // add to list at bottom of page
        this.$store.commit('project/addToListInCurrent', {
          key: 'project_extensions',
          value: {
            uid,
            extension: this.extensions.find((ext) => ext.uid === uid).name,
            version: v.uid,
            env: v.env
          }
        });
      }
    },
    delRel({ entityType, uid }) {
      if (entityType === 'Extension') {
        // remove from list at bottom of page
        this.$store.commit('project/removeFromListInCurrent', { key: 'project_extensions', uid });
      }
    },
    updExtra({ entityType, key, value }) {
      if (entityType === 'Extension') {
        this.$store.commit('project/updateExtensionVersion', {
          name: this.extensions.find((ext) => ext.uid === key).name,
          version: value,
          env: this.versions.find((v) => v.uid === value).env,
        });
      }
    },
  },
  watch: {
    user() {
      this.load();
    },
  },
  created() {
    this.load();
  },

  beforeRouteUpdate(to, from, next) {
    this.$refs.content.checkPageLeave(to, from, next);
  },
  beforeRouteLeave(to, from, next) {
    this.$refs.content.checkPageLeave(to, from, next);
  },
};
</script>
