<template>
  <div class="main-wrapper pa-8">
    <NewExtensionModal
      :open="showNewModal"
      @close="showNewModal = false"
      @create="create"
    ></NewExtensionModal>

    <EntitiesListTemplate
      :items="filteredExtensions"
      title="Extensions"
      type="extension"
      full-height
      @clicked-category="selectCategory"
    >
      <template #before-list-search>
        <v-btn v-if="isAdmin" class="mr-4" :color="`${color} lighten-3`" @click="showNewModal = true">
          create
        </v-btn>
      </template>

      <template #list-search>
        <v-row align="center" class="mb-4" no-gutters>
          <v-text-field
            v-model="extensionSearch"
            placeholder="Find extension..."
            prepend-inner-icon="mdi-magnify"
            outlined
            clearable
            dense
            hide-details
            class="mr-4"
          ></v-text-field>
          <v-menu left offset-y :close-on-content-click="false">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                :color="hasAdvancedSettings ? 'black' : 'accent'"
                :dark="hasAdvancedSettings"
                :outlined="!hasAdvancedSettings"
                v-bind="attrs"
                v-on="on"
              >
                advanced
              </v-btn>
            </template>
            <v-list dense>
              <v-list-item>
                <v-checkbox
                  v-model="onlyDisabled"
                  label="Disabled only"
                  hide-details
                ></v-checkbox>
              </v-list-item>
              <v-list-item>
                <v-checkbox
                  v-model="onlyUnused"
                  label="Unused only"
                  hide-details
                ></v-checkbox>
              </v-list-item>
  
              <v-divider class="mt-4"></v-divider>
              <v-subheader>In categories:</v-subheader>
  
              <v-list-item v-for="cat in getAllCategories()" :key="cat">
                <v-checkbox dense v-model="searchCategories" :value="cat" :label="cat" hide-details></v-checkbox>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-row>
      </template>
    </EntitiesListTemplate>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import colors from '@/helpers/colors.js';

import EntitiesListTemplate from '@/views/EntitiesListTemplate.vue';
import NewExtensionModal from '@/components/NewExtensionModal.vue';

export default {
  name: 'Extensions',
  components: { EntitiesListTemplate, NewExtensionModal },
  data() {
    return {
      extensionSearch: '',
      showAdvanced: false,
      onlyDisabled: false,
      onlyUnused: false,
      searchCategories: [],

      showNewModal: false,
    };
  },
  computed: {
    ...mapState({
      extensions: (state) => state.extension.extensions,
    }),
    ...mapGetters({
      getAllCategories: 'extension/getAllCategories',
    }),
    color() { return colors.extension },
    isAdmin() {
      return this.$store.getters['user/isAdmin']();
    },
    filteredExtensions() {
      let ext = this.extensions;
      if (this.onlyDisabled)
        ext = ext.filter((e) => !e.is_enabled);
      if (this.onlyUnused)
        ext = ext.filter((e) => this.$store.getters['extension/isUnused'](e.uid));
      if (this.extensionSearch) {
        const s = this.extensionSearch.toLowerCase();
        ext = ext.filter((e) => e.name.toLowerCase().includes(s));
      }
      if (this.searchCategories.length > 0) {
        ext = ext.filter((e) => e.categories.some((c) => this.searchCategories.includes(c)));
      }

      return ext;
    },
    hasAdvancedSettings() {
      return this.onlyDisabled || this.searchCategories.length > 0;
    },
  },
  methods: {
    selectCategory(c) {
      console.log(c)
      this.searchCategories = [c];
    },
    async create(data) {
      try {
        const newEntity = await this.$store.dispatch('extension/create', { data });
        this.$router.push({ name: 'extension', params: { id: newEntity.uid } });
      } finally {
        this.showNewModal = false;
      }
    },
  },
};
</script>
