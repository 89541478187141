<template>
  <div id="app">
    <v-app id="application">
      <div v-if="loadingUser"></div>
      <InitialLoader v-else-if="loading"></InitialLoader>
      <template v-else>
        <AppBar v-if="$route.name !== 'login'"></AppBar>
        <v-overlay :value="innerLoading">
          <v-progress-circular
            indeterminate
            size="64"
          ></v-progress-circular>
        </v-overlay>
        <v-main>
          <router-view></router-view>
        </v-main>
      </template>
    </v-app>
  </div>
</template>

<script>
import { mapState } from "vuex";

import AppBar from '@/components/AppBar.vue';
import InitialLoader from '@/views/InitialLoader.vue';

export default {
  name: 'App',
  components: { AppBar, InitialLoader },
  data() {
    return {
      loadingUser: true,
    };
  },
  computed: {
    ...mapState({
      loading: (state) => state.global.loading,
      innerLoading: (state) => state.global.innerLoading,
    }),
  },
  async created() {
    if (this.$store.getters['user/hasLocalToken']()) {
      await this.$store.dispatch('user/getCurrentUser');
      this.$store.commit('global/setLoading', { loading: true });
    }
    this.loadingUser = false;

    const usesDarkMode = parseInt(localStorage.getItem('__overseer__dark-mode'));
    this.$vuetify.theme.dark = usesDarkMode === 1;
    document.body.setAttribute('theme', this.$vuetify.theme.dark ? 'dark' : 'light');
  }
};
</script>

<style>
h3, h4 {
  margin-bottom: 0.5rem;
}
h3:not(:first-child) {
  margin-top: 0.5rem;
}
h4:not(:first-child) {
  margin-top: 1rem;
}

#application {
  --app-bar-height: 64px;
  --banner-height: 53px;
  overflow-y: hidden;
}

#app-bar {
  z-index: 999;
  height: var(--app-bar-height);
}

#content {
  display: grid;
  grid-template-columns: auto 1fr;
  height: calc(100vh - var(--app-bar-height));
  overflow-y: hidden;
}

#sidebar {
  min-width: 20vw;
  border-right: 1px solid #ddd;
  height: calc(100vh - var(--app-bar-height));
}

.sidebar-header {
  font-weight: 500;
  font-size: 1.1rem;
  padding: 0.2rem;
  cursor: pointer;
  background-color: transparent;
  transition: background-color 0.35s ease-in-out;
}
.sidebar-header:hover {
  background-color: #ddd;
}
.sidebar-header--active {
  background-color: #efefef;
}

.sidebar-item {
  margin-left: 2rem;
  font-size: 1rem;
  opacity: 0.65;
  cursor: pointer;
  transition: opacity 0.35s ease-in-out;
}
.sidebar-item:hover {
  opacity: 1;
}

#main {
  overflow-y: auto;
  height: calc(100vh - var(--app-bar-height) - var(--banner-height));
}

.status-pill {
  --size: 24px;
  width: var(--size);
  height: var(--size);
  border-radius: 50%;
  cursor: pointer;
}
.status-pill:hover {
  filter: brightness(90%);
}
.status-pill:active {
  filter: brightness(70%);
}

.modified-input .v-input__slot {
  background-color: #ffe0b2 !important;
}
</style>
