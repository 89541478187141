<template>
  <v-dialog :value="open" @input="(v) => $emit('update-popup', v)" max-width="600px">
    <v-card>
      <v-card-title>
        <span class="text-h5">Add {{ type }} override</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          Add an override {{ type }} for this project's environment variables:
          <v-select
            :placeholder="`Pick a ${type}`"
            v-model="value"
            :items="items"
            hide-selected
            hide-details
            item-text="name"
            :item-value="itemValue"
          ></v-select>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="accent"
          text
          @click="$emit('update-popup', false)"
        >
          Cancel
        </v-btn>
        <v-btn
          color="primary"
          text
          @click="onSubmit(value)"
        >
          Add
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'NewOverridePopup',
  props: {
    open: { type: Boolean, required: true },
    type: { type: String, required: true },
    items: { type: Array, required: true },
    itemValue: { type: String, required: true },
    onSubmit: { type: Function, required: true },
  },
  data() {
    return {
      value: '',
    };
  },
  watch: {
    items(newItems) {
      if (newItems.length) this.value = newItems[0][this.itemValue];
    },
  },
};
</script>
