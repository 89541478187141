<template>
  <v-dialog :value="open" @input="(v) => $emit('update-popup', v)" max-width="600px">
    <v-card>
      <v-card-title>
        <span class="text-h5">Duplicate entity</span>
      </v-card-title>
      <v-card-text>
        <div class="text-body-1 mb-4">Create a new {{ type }} based on this one:</div>
        <v-text-field
          :value="name"
          @input="(v) => $emit('update-name', v)"
          label="New name"
          :rules="[rules.required]"
        ></v-text-field>
        <slot name="duplicate"></slot>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="accent"
          text
          @click="$emit('update-popup', false)"
        >
          cancel
        </v-btn>
        <v-btn
          color="primary"
          text
          :disabled="this.name.length === 0 || !isValid"
          @click="$emit('duplicate')"
        >
          duplicate
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import rules from '@/helpers/rules.js';

export default {
  name: 'DuplicationPopup',
  props: {
    open: { type: Boolean, required: true },
    type: { type: String, required: true },
    name: { type: String, default: '' },
    isValid: { type: Boolean, default: true },
  },
  data() {
    return {
      rules,
    };
  },
};
</script>
