var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.currentSite)?_c('EntityTemplate',{ref:"content",attrs:{"listed-entities":_vm.sites,"entity":_vm.currentSite,"data-is-dirty":_vm.dataIsDirty,"reload-fn":_vm.reload,"on-duplicate":_vm.onDuplicate,"duplicate-is-valid":_vm.duplicateIsValid,"name":_vm.currentSite.name,"type":"site","can-create":_vm.isAdmin,"can-duplicate":_vm.isAdmin,"can-delete":_vm.isAdmin,"sidebar-title":"Sites","create-modal-component":"NewSiteModal","create-btn-label":"Create site","open-attributes":_vm.openAttributes},on:{"set-open-attributes":(v) => _vm.openAttributes = v},scopedSlots:_vm._u([{key:"duplicate",fn:function(){return [_c('v-text-field',{staticClass:"mt-4",attrs:{"label":"New code","rules":[_vm.rules.required, _vm.duplicateHasValidCode || 'Another site already uses this code']},model:{value:(_vm.duplicateCode),callback:function ($$v) {_vm.duplicateCode=$$v},expression:"duplicateCode"}})]},proxy:true}],null,false,2153501131)},[[_c('AttributesPanel',{attrs:{"title":"Global","data":_vm.currentSite,"backup":_vm.siteBackup,"attributes":[
        { name: 'code', title: 'Code', component: 'v-text-field', rules: [_vm.rules.required] },
        { name: 'name', title: 'Name', component: 'v-text-field', rules: [_vm.rules.required] },
        { name: 'description', title: 'Description', component: 'v-textarea', extra: { 'auto-grow': true, rows: 2 } },
        { name: 'type', title: 'Type', component: 'v-select', rules: [_vm.rules.required], extra: {
          items: [
            { value: 'studio', text: 'Studio' },
            { value: 'user', text: 'Remote' },
          ]
        } },
      ]},on:{"update":({ attr, value }) => _vm.$refs.content.updateAttribute(attr, value)}})]],2):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }