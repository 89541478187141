export const extension = {
    defaults: {
        name: '',
        description: '',
        categories: [],
    },
    categories: [
        'blender',
        'rigging',
        'animation',
        'modeling',
    ]
}

export const project = {
    libreflow: '1.0.0',

    redisUrl: '://my-redis',
    redisPassword: process.env.VUE_APP_DEFAULT_REDIS_PASSWORD || '',
    redisCluster: '<cluster>',

    mongoUrl: '://my-mongo',
    mongoUser: 'mongo-user',
    mongoPassword: process.env.VUE_APP_DEFAULT_MONGO_PASSWORD || '',

    exchangeServerUrl: '://my-exchange-server',
    exchangeServerLogin: 'exch-login',
    exchangeServerPassword: process.env.VUE_APP_DEFAULT_EXCHANGE_SERVER_PASSWORD || '',
    exchangeServerBucket: '<bucket>',
};

export const site = {
    description: '',
    type: 'studio'
};
