import Vue from 'vue';
import VueRouter from 'vue-router';
import Dashboard from '../views/Dashboard.vue';
import Extensions from '../views/Extensions.vue';
import Extension from '../views/Extension.vue';
import Projects from '../views/Projects.vue';
import Project from '../views/Project.vue';
import Sites from '../views/Sites.vue';
import Site from '../views/Site.vue';
import Users from '../views/Users.vue';
import User from '../views/User.vue';

import store from '../store';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'dashboard',
    component: Dashboard,
  },
  {
    path: '/e',
    name: 'extensions',
    component: Extensions,
  },
  {
    path: '/e/:id',
    name: 'extension',
    component: Extension,
  },
  {
    path: '/p',
    name: 'projects',
    component: Projects,
  },
  {
    path: '/p/:id',
    name: 'project',
    component: Project,
  },
  {
    path: '/s',
    name: 'sites',
    component: Sites,
  },
  {
    path: '/s/:id',
    name: 'site',
    component: Site,
  },
  {
    path: '/u',
    name: 'users',
    component: Users,
  },
  {
    path: '/u/:id',
    name: 'user',
    component: User,
  },
  {
    path: '/login',
    name: 'login',
    // route level code-splitting
    // this generates a separate chunk (login.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "login" */ '../views/Login.vue'),
  },
  {
    path: '/restricted',
    name: 'restricted',
    component: () =>
      import('../views/Restricted.vue'),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, _, next) => {
  const hasToken = store.getters['user/hasLocalToken']();
  if (!hasToken && to.name !== 'login') {
    const redirect = typeof to === 'string' ? to : to.name;
    next({ name: 'login', params: { redirect } });
  } else if (hasToken && to.name === 'login') {
    const redirect = typeof to === 'string' ? to : to.name;
    next({ name: 'dashboard', params: { redirect } });
  } else {
    next();
  }
});

export default router;
