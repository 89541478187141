<template>
  <v-dialog :value="open" max-width="50vw" scrollable @click:outside="$emit('close')" @keydown.esc="$emit('close')">
    <v-card>
      <v-card-title>
        <span class="text-h5">New site</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-form ref="form" v-model="formIsValid">
            <div class="attributes-block">
              <div class="attributes-item">
                <label>Code</label>
                <v-text-field
                  v-model="code"
                  :rules="[rules.required, rules.codeNotUsed]"
                  dense
                ></v-text-field>
              </div>
              <div class="attributes-item">
                <label>Name</label>
                <v-text-field
                  v-model="name"
                  :rules="[rules.required]"
                  dense
                ></v-text-field>
              </div>
              <div class="attributes-item">
                <label>Description</label>
                <v-textarea
                  v-model="description"
                  auto-grow
                  dense
                  :rows="2"
                ></v-textarea>
              </div>
              <div class="attributes-item">
                <label>Type</label>
                <v-select
                  v-model="type"
                  :items="[
                    { value: 'studio', text: 'Studio' },
                    { value: 'user', text: 'Remote' },
                  ]"></v-select>
              </div>
            </div>
          </v-form>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="accent"
          text
          @click="$emit('close')"
        >
          Cancel
        </v-btn>
        <v-btn
          color="primary"
          text
          :disabled="!formIsValid"
          @click="createSite"
        >
          Create
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex';
import rules from '@/helpers/rules.js';
import { site as siteDefaults } from '@/helpers/models/defaults.js';

export default {
  name: 'NewSiteModal',
  props: {
    open: { type: Boolean, required: true },
  },
  data() {
    return {
      formIsValid: false,
      rules: {
        ...rules,
        codeNotUsed: (value) => !this.usedExtensionCodes.includes(value) || 'Code already taken',
      },

      code: '',
      name: '',
      description: siteDefaults.description,
      type: siteDefaults.type,
    };
  },
  computed: {
    ...mapState({
      sites: (state) => state.site.sites,
    }),
    usedExtensionCodes() {
      return this.sites.map((s) => s.code);
    },
  },
  methods: {
    createSite() {
      const data = {
        code: this.code,
        name: this.name,
        description: this.description,
        type: this.type,
      };
      this.$emit('create', data);

      // reset modal
      this.code = '';
      this.name = '';
      this.description = siteDefaults.description;
      this.type = siteDefaults.type;
      this.$refs.form.resetValidation();
    },
  },
};
</script>

<style scoped>
.attributes-block {
  display: grid;
  grid-template-columns: 1fr 2fr;
  padding-right: 2rem;
  align-items: center;
}

.attributes-item {
  display: contents;
}

.attributes-item > label {
  font-weight: 500;
  font-size: 0.9rem;
  text-transform: capitalize;
  margin-left: 2rem;
}
</style>
