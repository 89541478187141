import axios from 'axios';
import cloneDeep from 'lodash.clonedeep';
import pluralize from 'pluralize';
import Vue from 'vue';

import * as sorters from '@/helpers/models/sorters';
import * as formatters from '@/helpers/models/formatters';
import { objGet, computeEntityDifference } from '@/helpers/misc.js';

export const makeEntityStore = (name, options) => {
  const extraState = objGet(options, 'state', {});
  const extraGetters = objGet(options, 'getters', {});
  const extraMutations = objGet(options, 'mutations', {});
  const extraActions = objGet(options, 'actions', {});
  const beforeCreate = objGet(options, 'beforeCreate', null);
  const postprocessCreate = objGet(options, 'postprocessCreate', null);
  const afterCreate = objGet(options, 'afterCreate', null);
  const beforeSave = objGet(options, 'beforeSave', null);
  const afterSave = objGet(options, 'afterSave', null);
  const beforeDuplicate = objGet(options, 'beforeDuplicate', null);
  const postprocessDuplicate = objGet(options, 'postprocessDuplicate', null);
  const afterDuplicate = objGet(options, 'afterDuplicate', null);
  const beforeDelete = objGet(options, 'beforeDelete', null);
  const afterDelete = objGet(options, 'afterDelete', null);
  const currentTransformer = objGet(options, 'currentTransformer', null);
  const onAddRelationsToCurrent = objGet(options, 'onAddRelationsToCurrent', null);
  const onRemoveRelationsFromCurrent = objGet(options, 'onRemoveRelationsFromCurrent', null);

  const namep = pluralize(name); // pluralized name
  const namet = name[0].toUpperCase() + name.slice(1); // titleized name
  const nametp = namep[0].toUpperCase() + namep.slice(1); // titleized + pluralized name

  const backupKey = `${name}Backup`;
  const currentKey = `current${namet}`;

  const state = () => ({
    [namep]: [],
    [backupKey]: null,
    [currentKey]: null,
    dataIsDirty: false,
    ...extraState
  });
  
  const getters = {
    getCurrent: (state) => () => {
      return state[currentKey];
    },
    getBackup: (state) => () => {
      return state[backupKey];
    },
    getCurrentRelations: (state) => () => {
      return state[currentKey]?.__relations;
    },
    getBackupRelations: (state) => () => {
      return state[backupKey]?.__relations;
    },
    getRelationsDiff: (state) => () => {
      const o = state[backupKey].__relations;
      const n = state[currentKey].__relations;
      return Object.keys(o).reduce((acc, entityType) => {
        return { ...acc, [entityType]: {
          toKeep: n[entityType].items.filter((x) => o[entityType].items.includes(x)),
          ...computeEntityDifference(o[entityType].items, n[entityType].items)
        } };
      }, {});
    },
    ...extraGetters
  };
  
  const mutations = {
    // global
    [`set${nametp}`](state, { [namep]: instances }) {
      state[namep] = sorters[`sort${nametp}`](instances);
    },
    [`set${namet}`](state, { [name]: instance }) {
      const instances = [...state[namep]];
      const idx = instances.findIndex((i) => i.uid === instance.uid);
      instances[idx] = instance;
      state[namep] = sorters[`sort${nametp}`](instances);
    },
    [`update${namet}`](state, { uid, key, value }) {
      const idx = state[namep].findIndex((i) => i.uid === uid);
      Vue.set(state[namep][idx], key, value);
    },
    [`update${nametp}`](state, { uids, key, value }) {
      const instances = cloneDeep(state[namep]);
      instances.filter((i) => uids.includes(i.uid)).forEach((i) => {
        i[key] = cloneDeep(value);
      });
      state[namep] = instances;
    },
    [`add${namet}`](state, { [name]: instance }) {
      state[namep] = [ ...state[namep], instance ];
    },
    [`add${nametp}`](state, { [namep]: instances }) {
      state[namep] = [ ...state[namep], ...instances ];
    },
    [`remove${nametp}`](state, { uids }) {
      state[namep] = state[namep].filter((i) => !uids.includes(i.uid));
    },
    [`addToListIn${nametp}`](state, { uids, key, value }) {
      const instances = cloneDeep(state[namep]);
      instances.filter((i) => uids.includes(i.uid)).forEach((i) => {
        i[key].push(cloneDeep(value));
      });
      state[namep] = instances;
    },
    [`removeFromListIn${nametp}`](state, { uids, key, delUid }) {
      const instances = cloneDeep(state[namep]);
      instances.filter((i) => uids.includes(i.uid)).forEach((i) => {
        i[key] = i[key].filter((x) => x.uid !== delUid);
      });
      state[namep] = instances;
    },

    setCurrent(state, { [currentKey]: instance }) {
      if (currentTransformer)
        instance = currentTransformer(state, instance);
      const hasRelations = state[currentKey] && '__relations' in state[currentKey];
      const r = hasRelations ? cloneDeep(state[currentKey].__relations) : null;
      const current = cloneDeep(instance);
      if (hasRelations) current.__relations = r;
      state[currentKey] = current;
      state[backupKey] = cloneDeep(current);
      state.dataIsDirty = false;
    },
    updateCurrent(state, { key, value }) {
      if (state[currentKey] === null) return;
      Vue.set(state[currentKey], key, value);
      state.dataIsDirty = true;
    },
    addToListInCurrent(state, { key, value }) {
      if (state[currentKey] === null) return;
      Vue.set(state[currentKey], key, [ ...state[currentKey][key], value ]);
      state.dataIsDirty = true;
    },
    removeFromListInCurrent(state, { key, uid }) {
      if (state[currentKey] === null) return;
      if (typeof uid === 'string')
        Vue.set(state[currentKey], key, state[currentKey][key].filter((i) => i.uid !== uid) );
      else
        Vue.set(state[currentKey], key, state[currentKey][key].filter((i) => !uid.includes(i.uid)) );
      state.dataIsDirty = true;
    },
    undoChanges(state) {
      state[currentKey] = cloneDeep(state[backupKey]);
      state.dataIsDirty = false;
    },
    cleanChanges(state) {
      state.dataIsDirty = false;
    },
  
    setCurrentRelations(state, { relations }) {
      if (state[currentKey] === null) return;
      Vue.set(state[currentKey], '__relations', relations);
      Vue.set(state[backupKey], '__relations', cloneDeep(relations));
    },
    addRelationsToCurrent(state, { entityType, relations }) {
      const r = cloneDeep(state[currentKey].__relations);
      for (const rel of relations) {
        r[entityType].items.push(rel.item);
        r[entityType].extra = {  ...r[entityType].extra, ...(rel.extra || {}) };
      }
      Vue.set(state[currentKey], '__relations', r);
      state.dataIsDirty = true;
    },
    removeRelationsFromCurrent(state, { entityType, relations }) {
      const r = state[currentKey].__relations[entityType];
      Vue.set(state[currentKey].__relations[entityType], 'items',
        r.items.filter((i) => !relations.includes(i)));
      state.dataIsDirty = true;
    },
    pushCurrentRelations(state) {
      Vue.set(state[backupKey], '__relations', state[currentKey].__relations);
    },
  
    ...extraMutations
  };
  
  const actions = {
    addRelationsToCurrent(store, data) {
      store.commit('addRelationsToCurrent', data);
      if (onAddRelationsToCurrent)
        onAddRelationsToCurrent(store, data);
    },
    removeRelationsFromCurrent(store, data) {
      store.commit('removeRelationsFromCurrent', data);
      if (onRemoveRelationsFromCurrent)
        onRemoveRelationsFromCurrent(store, data);
    },

    async [`get${nametp}`]({ commit, rootState }) {
      let { data } = await axios.get(`/${namep}?limit=1000`);
      data = formatters[`${namep}FromApi`](data, rootState);
      commit(`set${nametp}`, { [namep]: data });
      return data;
    },
    async create(store, { data }) {
      const { commit, rootState } = store;
      if (beforeCreate) data = beforeCreate(store, data);
      if (name === 'project')
        data.data = formatters[`${name}ToApi`](data.data, rootState);
      else
        data = formatters[`${name}ToApi`](data, rootState);
      let { data: result } = await axios.post(`/${namep}`, data);
      if (postprocessCreate) result = postprocessCreate(store, result);
      result = formatters[`${name}FromApi`](result, rootState);
      commit(`add${namet}`, { [name]: result });
      if (afterCreate) afterCreate(store, result);
      return result;
    },
    async save(store) {
      const { commit, state, rootState } = store;
      const data = cloneDeep(state[currentKey]);

      let update = data;
      if (beforeSave) update = await beforeSave(store, data);
      update = formatters[`${name}ToApi`](update);
      const { uid } = update;
      delete update.uid;
      delete update.__relations;
  
      let { data: result } = await axios.patch(`/${namep}/${uid}`, update);
      result = formatters[`${name}FromApi`](result, rootState);
      commit(`set${namet}`, { [name]: result });
      commit('setCurrent', { [currentKey]: result });
      commit('cleanChanges');
      if (afterSave) afterSave(store);
    },
    async duplicate(store, { origin }) {
      const { commit, rootState } = store;

      let duplicate = origin;
      if (beforeDuplicate) duplicate = await beforeDuplicate(store, duplicate);
      duplicate = formatters[`${name}ToApi`](duplicate);
      delete duplicate.__relations;
  
      let { data: result } = await axios.post(`/entity-duplicate/${namet}`, duplicate);
      if (result) {
        if (postprocessDuplicate) result = postprocessDuplicate(store, result);
        result = formatters[`${name}FromApi`](result, rootState);
        commit(`add${namet}`, { [name]: result });
  
        if (afterDuplicate) await afterDuplicate(store, origin, result);
      }
      return result;
    },
    async delete(store, { uid }) {
      if (beforeDelete) await beforeDelete(store, uid);
      try {
        await axios.delete(`/${namep}/${uid}`);
        if (afterDelete) await afterDelete(store, uid);
      } catch(e) {
        console.error(e);
      }
    },

    ...extraActions
  };

  return {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
  };
}
