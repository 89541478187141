<template>
  <div>
    <NewOverridePopup
      :open="showNewOverridePopup"
      :type="overridePopupType"
      :items="overrideItems"
      :item-value="overridePopupType === 'site' ? 'uid' : 'login'"
      :on-submit="addExtensionOverride"
      @update-popup="(on) => showNewOverridePopup = on"
    />
    <DeleteOverridePopup
      :open="showDeleteOverridePopup"
      @delete="removeExtensionOverride"
      @update-popup="(on) => showDeleteOverridePopup = on"
    ></DeleteOverridePopup>
    
    <div v-if="open" class="mb-2">
      <v-row no-gutters align="center" class="mb-3">
        <v-icon class="mr-2" @click="open = false">mdi-chevron-down</v-icon>
        <div>{{ extension.extension }}</div>
        <v-chip class="ml-2" :class="{ 'font-weight-bold': nOverrides > 0 }" :ripple="false" label small>
          {{ summary }}
        </v-chip>
        <v-btn icon :to="{ name: 'extension', params: { id: extension.uid } }">
          <v-icon>mdi-location-enter</v-icon>
        </v-btn>
      </v-row>
      <v-alert
        border="left"
        color="accent lighten-1"
        class="ml-4 py-2"
        colored-border
        :elevation="1"
      >
        <h5>Site overrides <span v-if="!siteOverrides || siteOverrides.length === 0" class="ml-2">(0)</span></h5>
        <v-row v-for="override, idx in siteOverrides" :key="`${extension.extension}-site-override-${idx}`" no-gutters align="center">
          <div>{{ override.name }}:</div>
          <v-select
            class="ml-8 mr-4 mt-0"
            :items="versionItems"
            :value="override.version"
            @input="(value) => updateExtensionOverrideValue('site', override.site, value)"
          ></v-select>
          <v-btn fab outlined x-small :elevation="0" @click="openDeleteOverridePopup('site', idx)">
            <v-icon>mdi-trash-can-outline</v-icon>
          </v-btn>
        </v-row>
        <v-row v-if="availableSites.length > 0" class="mt-1" no-gutters align="center">
          <v-btn class="mr-2" icon outlined x-small :elevation="0" @click="openNewOverridePopup('site')">
            <v-icon>mdi-plus</v-icon>
          </v-btn>
          <div>Add override</div>
        </v-row>
      </v-alert>
      <v-alert
        border="left"
        color="accent lighten-1"
        class="ml-4 py-2"
        colored-border
        :elevation="1"
      >
        <h5>User overrides <span v-if="!userOverrides || userOverrides.length === 0" class="ml-2">(0)</span></h5>
        <v-row v-for="override, idx in userOverrides" :key="`${extension.extension}-user-override-${idx}`" no-gutters align="center">
          <div>{{ override.name }}:</div>
          <v-select
            class="ml-8 mr-4 mt-0"
            :items="versionItems"
            :value="override.version"
            @input="(value) => updateExtensionOverrideValue('user', override.user, value)"
          ></v-select>
          <v-btn fab outlined x-small :elevation="0" @click="openDeleteOverridePopup('user', idx)">
            <v-icon>mdi-trash-can-outline</v-icon>
          </v-btn>
        </v-row>
        <v-row v-if="availableUsers.length > 0" class="mt-1" no-gutters align="center">
          <v-btn class="mr-2" icon outlined x-small :elevation="0" @click="openNewOverridePopup('user')">
            <v-icon>mdi-plus</v-icon>
          </v-btn>
          <div>Add override</div>
        </v-row>
      </v-alert>
      <v-alert
        v-if="env.length > 0"
        border="left"
        color="primary lighten-1"
        class="ml-4 py-2"
        colored-border
        :elevation="1"
      >
        <EnvVarsBlock
          class="ml-4"
          :items="env"
          :backup-items="backupEnv"
          :default-items="defaultEnv"
          :block-id="`extension-env-vars-${extension.extension}`"
          add-label="Add variable"
          locked-keys
          @update-value="({ key, value }) => updateEnvVarValue(key, value)" />
      </v-alert>
    </div>
    <div v-else class="mb-2">
      <v-row no-gutters align="center">
        <v-icon class="mr-2" @click="open = true">mdi-chevron-right</v-icon>
        <div>{{ extension.extension }}</div>
        <v-chip class="ml-2" :class="{ 'font-weight-bold': nOverrides > 0 }" :ripple="false" label small>
          {{ summary }}
        </v-chip>
        <v-btn icon :to="{ name: 'extension', params: { id: extension.uid } }">
          <v-icon>mdi-location-enter</v-icon>
        </v-btn>
      </v-row>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import cloneDeep from 'lodash.clonedeep';
import flatten from 'lodash.flatten';
import uniq from 'lodash.uniq';
import uniqBy from 'lodash.uniqby';
import { uidsToObjects } from '@/helpers/misc.js';

import DeleteOverridePopup from '@/components/DeleteOverridePopup.vue';
import EnvVarsBlock from '@/components/EnvVarsBlock.vue';
import NewOverridePopup from '@/components/NewOverridePopup.vue';

export default {
  name: 'ExtensionDisplay',
  components: { DeleteOverridePopup, EnvVarsBlock, NewOverridePopup },
  props: {
    extension: { type: Object, required: true },
    availableExtensions: { type: Array, required: true },
  },
  data() {
    return {
      open: false,

      overridePopupType: 'site',
      overrideItems: [],
      overrideValue: -1,
      overrideExtra: '',
      showNewOverridePopup: false,
      showDeleteOverridePopup: false,
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.user.user,
      users: (state) => state.user.users,
      sites: (state) => state.site.sites,
      extensions: (state) => state.extension.extensions,
      currentProject: (state) => state.project.currentProject,
      projectBackup: (state) => state.project.projectBackup,
    }),
    backupExtension() {
      return this.projectBackup.project_extensions.find((e) => e.uid === this.extension.uid);
    },
    defaultEnv() {
      if (!this.currentProject) return [];
      const env = {};
      for (const { key, value } of this.getEnv(this.currentProject, true))
        env[key] = value;
      return env;
    },
    env() {
      if (!this.currentProject) return [];
      return this.getEnv(this.currentProject);
    },
    backupEnv() {
      if (!this.projectBackup) return [];
      return this.getEnv(this.projectBackup);
    },
    siteOverrides() {
      if (!this.currentProject) return null;
      const o = [ ...this.currentProject.site_extensions[this.extension.extension] || [] ];
      o.sort((a, b) => a['name'] < b['name'] ? 1 : -1);
      return o;
    },
    userOverrides() {
      if (!this.currentProject) return null;
      const o = [ ...this.currentProject.user_extensions[this.extension.extension] || [] ];
      o.sort((a, b) => a['name'] < b['name'] ? 1 : -1);
      return o;
    },
    nOverrides() {
      return this.siteOverrides.length + this.userOverrides.length;
    },
    availableSites() {
      if (!this.user || !this.siteOverrides) return [];
      return this.sites.filter((s) => this.siteOverrides.findIndex((i) => i.site === s.uid) === -1);
    },
    availableUsers() {
      if (!this.users || !this.userOverrides) return [];
      return this.users.filter((u) => this.userOverrides.findIndex((i) => i.user === u.login) === -1);
    },
    extensionItems() {
      return this.availableExtensions.map((ext) => ({
        text: ext.name,
        value: ext.name,
      })).concat([{ text: this.extension.extension, value: this.extension.extension }]);
    },
    versionItems() {
      const ext = this.extensions.find((e) => e.name === this.extension.extension);
      const vers = uidsToObjects(this.$store.state, 'version', ext.versions, { packed: true });
      return [
        { text: '<default>', value: '__default' },
        ...vers.map((v) => ({ text: v.name, value: v.uid }))
      ];
    },
    summary() {
      let v = this.versionItems.find((i) => i.value === this.extension.version).text;
      if (v === '<default>') {
        const ext = this.extensions.find((e) => e.uid === this.extension.uid);
        v = `${v} (=${this.versionItems.find((v) => v.value === ext.defaultVersionUid).text})`;
      }
      if (this.nOverrides > 0)
        v = `${v}*${this.nOverrides}`;
      return v;
    },
    currentVersion() {
      return this.versionItems.find((i) => i.value === this.extension.version).text;
    },
  },
  methods: {
    getEnv(project, getDefault = false) {
      const ext = this.extensions.find((e) => e.uid === this.extension.uid);
      let vUids = [this.extension.version];
      if (this.extension.extension in project.site_extensions)
        vUids.push(...project.site_extensions[this.extension.extension].map((x) => x.version));
      if (this.extension.extension in project.user_extensions)
        vUids.push(...project.user_extensions[this.extension.extension].map((x) => x.version));
      for (let i = 0; i < vUids.length; i++) {
        if (vUids[i] === '__default') {
          vUids[i] = ext.defaultVersionUid;
        }
      }
      vUids = uniq(vUids);
      const versions = this.$store.state.version.versions.filter((v) => vUids.includes(v.uid));
      const env = cloneDeep(uniqBy(flatten(versions.map((v) => v.env)), (x) => x.key));
      if (getDefault) return env;

      const projectExt = project.project_extensions.find((e) => e.uid === this.extension.uid);
      if (!projectExt) return env;
      for (const { key, value } of projectExt.overrideEnv) {
        const idx = env.findIndex((x) => x.key === key);
        if (idx !== -1)
          env[idx].value = value;
      }
      env.sort((x1, x2) => x1.key > x2.key);
      return env;
    },
    removeExtension() {
      this.$store.commit('project/removeExtension', { name: this.extension.extension });
    },
    updateExtension(value) {
      const defaultVersionUid = this.extensions.find((e) => e.name === value).versions[0].item;
      const defaultVersion = uidsToObjects(this.$store.state, 'version', [defaultVersionUid], { single: true });
      this.$store.commit('project/updateExtension', {
        name: this.extension.extension, value,
        version: defaultVersion.uid,
        env: (defaultVersion.env === '') ? [] : JSON.parse(defaultVersion.env) });
    },
    updateExtensionVersion(version) {
      const name = this.extension.extension;
      const ver = uidsToObjects(this.$store.state, 'version', [version], { single: true });
      const env = ver.env.map(({ key, value }) => ({ key, dftValue: value, value }));
      this.$store.commit('project/updateExtensionVersion', { name, version, env });
      this.$emit('updated-extension-version', { extensionUid: this.extension.uid, versionUid: version });
    },
    openNewOverridePopup(type) {
      this.showNewOverridePopup = true;
      this.overridePopupType = type;
      this.overrideItems = (type === 'site')
        ? this.availableSites : this.availableUsers;
    },
    openDeleteOverridePopup(type, value) {
      this.showDeleteOverridePopup = true;
      this.overridePopupType = type;
      this.overrideValue = value;
    },
    addExtensionOverride(value) {
      let newItem;
      const { extension } = this.extension;
      const version = this.extensions.find((e) => e.name === extension).versions[0].item;
      const type = this.overridePopupType;
      if (type === 'site') {        
        const site = this.availableSites.find((s) => s.uid === value);
        newItem = { site: site.uid, code: site.code, name: site.name, version };
      } else {
        const user = this.availableUsers.find((u) => u.login === value);
        newItem = { user: user.login, name: user.name, version };
      }
      this.$store.commit('project/addExtensionOverride', { type, newItem, extension });
      this.showNewOverridePopup = false;
    },
    removeExtensionOverride() {
      const { extension } = this.extension;
      this.$store.commit('project/removeExtensionOverride', {
        type: this.overridePopupType,
        index: this.overrideValue,
        extension
      });
      this.showDeleteOverridePopup = false;
    },
    updateExtensionOverrideKey(type, oldKey, key, value) {
      const { extension } = this.extension;
      let extra = {};
      if (type === 'site') {
        const site = this.sites.find((s) => s.uid === key);
        extra = { code: site.code, name: site.name };
      } else {
        const user = this.users.find((u) => u.login === key);
        extra = { name: user.name };
      }
      this.$store.commit('project/updateExtensionOverrideKey', { type, oldKey, key, value, extension, extra });
    },
    updateExtensionOverrideValue(type, key, value) {
      const { extension } = this.extension;
      this.$store.commit('project/updateExtensionOverrideValue', { type, key, value, extension });
    },
    updateEnvVarValue(key, value) {
      this.$store.commit('project/updateEnvVarValue', { type: 'ext', key, value, item: this.extension.extension });
    },
  },
};
</script>

