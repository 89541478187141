const config = {
    // server base settings
    apiHost: process.env.VUE_APP_API_HOST || 'localhost',
    apiPort: parseInt(process.env.VUE_APP_API_PORT) || 5500,

    loggerLevel: 3,
    isDev: process.env.NODE_ENV !== 'production',
};

module.exports = config;
