import axios from 'axios';
import store from '@/store/index.js';
import router from '@/router/index.js';
import * as config from '../../node.config.js';

axios.defaults.baseURL = `https://${config.apiHost}`;

axios.interceptors.response.use(
    function (response) { return response; },
    function (error) {
        // any status code != 2xx
        if (error.response.status === 403) {
            const { detail } = error.response.data;
            if (detail === 'expired-token') {
                store.dispatch('user/logout');
                router.push({ name: 'login' });
            }
        }
        return Promise.reject(error);
    }
);
