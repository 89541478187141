<template>
  <div id="loader">
    <v-progress-circular
      indeterminate
      :size="160"
      :width="15"
      color="primary"
    ></v-progress-circular>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: 'InitialLoader',
  computed: {
    ...mapState({
      extensions: (state) => state.extension.extensions,
      projects: (state) => state.project.projects,
      sites: (state) => state.site.sites,
      users: (state) => state.user.users,
      versions: (state) => state.version.versions,
    }),
  },
  async created() {
    const promises = [];
    if (this.sites.length === 0)
      promises.push(this.$store.dispatch('site/getSites'));
    if (this.users.length === 0)
      promises.push(this.$store.dispatch('user/getUsers'));
    if (this.versions.length === 0)
      promises.push(this.$store.dispatch('version/getVersions'));
    if (this.extensions.length === 0)
      promises.push(this.$store.dispatch('extension/getExtensions'));
    await Promise.all(promises);
    if (this.projects.length === 0)
      await this.$store.dispatch('project/getProjects');

    this.$store.commit('global/setLoading', { loading: false });
  },
};
</script>

<style scoped>
#loader {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: grid;
  place-items: center;
}
</style>
