<template>
  <EntityTemplate
    ref="content"
    v-if="currentUser"
    :listed-entities="users"
    :entity="currentUser"
    :data-is-dirty="dataIsDirty"
    :reload-fn="reload"
    :name="currentUser.name || currentUser.login"
    type="user"
    sidebar-title="Users"
    create-btn-label="Create user"
    :open-attributes="openAttributes"
    @set-open-attributes="(v) => openAttributes = v"
  >
    <template #sidebar-pre>
      <div class="px-2" v-if="isAdmin">
        <v-btn class="my-2" block color="accent" @click="updateKeycloakUsers">
          update keycloak users
        </v-btn>
      </div>
    </template>

    <template>
      <AttributesPanel
        title="Global"
        :data="currentUser"
        :backup="userBackup"
        :attributes="[
          { name: 'login', title: 'Login', component: 'v-text-field', rules: [rules.required], extra: { 'disabled': true } },
          { name: 'name', title: 'Name', component: 'v-text-field', rules: [] },
          { name: 'description', title: 'Description', component: 'v-textarea', extra: { 'auto-grow': true, rows: 2 } },
        ]"
        @update="({ attr, value }) => $refs.content.updateAttribute(attr, value)"
      />
    </template>

  </EntityTemplate>
</template>

<script>
import { mapState } from 'vuex';
import rules from '@/helpers/rules.js';

import EntityTemplate from '@/views/EntityTemplate.vue';
import AttributesPanel from '@/components/AttributesPanel.vue';

export default {
  name: 'User',
  components: {
    EntityTemplate,
    AttributesPanel,
  },
  data() {
    return {
      openAttributes: [],
      rules,
    };
  },
  computed: {
    ...mapState({
      users: (state) => state.user.users,
      currentUser: (state) => state.user.currentUser,
      userBackup: (state) => state.user.userBackup,
      dataIsDirty: (state) => state.user.dataIsDirty,
    }),
    isAdmin() {
      return this.$store.getters['user/isAdmin']();
    },
  },
  methods: {
    async load() {
      this.$store.commit('global/setInnerLoading', { innerLoading: true });
      await this.prepareData();
    },
    async reload() {
      await this.load();
    },
    async prepareData() {
      if (this.users.length === 0)
        await this.$store.dispatch('user/getUsers');

      const user = this.users.find((u) => u.uid === this.$route.params.id);
      this.$store.commit('user/setCurrent', { currentUser: user });

      this.openAttributes = [
        0, // attributes
      ];

      const $this = this;
      this.$nextTick(() => {
        $this.$refs.content.setRelationData({
          Site: { title: 'In sites', modifiable: true },
          Project: { title: 'Works on', modifiable: true },
        });
      });
      this.$store.commit('global/setInnerLoading', { innerLoading: false });
    },
    async updateKeycloakUsers() {
      this.$store.commit('global/setInnerLoading', { innerLoading: true });
      try {
        await this.$store.dispatch('user/registerKeycloakUsers');
        const $this = this;
        this.$nextTick(() => {
          // if current user was removed during update, reroute to other page
          if ($this.users.findIndex((u) => u.uid === $this.$route.params.id) === -1) {
            $this.$refs.content.redirectToFirstEntity();
          }
        });
      } finally {
        this.$store.commit('global/setInnerLoading', { innerLoading: false });
      }
    },
  },
  created() {
    this.load();
  },

  beforeRouteUpdate(to, from, next) {
    this.$refs.content.checkPageLeave(to, from, next);
  },
  beforeRouteLeave(to, from, next) {
    this.$refs.content.checkPageLeave(to, from, next);
  },
};
</script>
