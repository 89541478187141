<template>
  <div class="main-wrapper pa-8">
    <NewProjectModal
      :open="showNewModal"
      @close="showNewModal = false"
      @create="create"
    ></NewProjectModal>

    <EntitiesListTemplate
      :items="projects"
      title="Projects"
      type="project"
      full-height
    >
      <template #before-list-search>
        <v-btn v-if="isAdmin" class="mr-4" :color="`${color} lighten-3`" @click="showNewModal = true">
          create
        </v-btn>
      </template>
    </EntitiesListTemplate>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import colors from '@/helpers/colors.js';

import EntitiesListTemplate from '@/views/EntitiesListTemplate.vue';
import NewProjectModal from '@/components/NewProjectModal.vue';

export default {
  name: 'Projects',
  components: { EntitiesListTemplate, NewProjectModal },
  data() {
    return {
      showNewModal: false,
    };
  },
  computed: {
    ...mapState({
      projects: (state) => state.project.projects,
    }),
    color() { return colors.project },
    isAdmin() {
      return this.$store.getters['user/isAdmin']();
    },
  },
  methods: {
    async create(data) {
      try {
        const newEntity = await this.$store.dispatch('project/create', { data });
        this.$router.push({ name: 'project', params: { id: newEntity.uid } });
      } finally {
        this.showNewModal = false;
      }
    },
  },
};
</script>
