<template>
  <div>
    <div class="attributes-block">
      <div v-for="item, i in items" :key="`${blockId}-${i}`" class="attributes-item">
        <label v-if="lockedKeys" :class="{ 'default': isDefault[item.key] }">{{ item.key }}</label>
        <v-text-field
          v-else
          :value="item.key"
          @input="(value) => $emit('update-key', { oldKey: item.key, key: value, value: item.value })"
          dense
          single-line
        ></v-text-field>
        <div class="mx-2">=</div>
        <v-text-field
          :value="item.value"
          @input="(value) => $emit('update-value', { key: item.key, value })"
          dense
          single-line
          :class="{ 'modified-input': modified[item.key] }"
        ></v-text-field>
        <v-btn v-if="!lockedKeys" class="ml-2" fab outlined x-small :elevation="0" @click="$emit('remove', i)">
          <v-icon>mdi-trash-can-outline</v-icon>
        </v-btn>
        <div v-else></div>
      </div>
    </div>
    <v-row v-if="!lockedKeys" no-gutters align="center">
      <v-btn class="mr-2" icon outlined x-small :elevation="0" @click="$emit('add')">
        <v-icon>mdi-plus</v-icon>
      </v-btn>
      <div>{{ addLabel }}</div>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'EnvVarsBlock',
  props: {
    blockId: { type: String, required: true },
    addLabel: { type: String, default: 'Add item' },
    items: { type: Array, required: true },
    backupItems: { type: Array, required: true },
    defaultItems: { type: Object, default: () => ({}) },
    lockedKeys: { type: Boolean, default: false },
  },
  computed: {
    isDefault() {
      const $this = this;
      return this.items.reduce((acc, i) => {
        return { ...acc, [i.key]: (i.key in $this.defaultItems && $this.defaultItems[i.key] === i.value) || false };
      }, {});
    },
    modified() {
      const $this = this;
      return this.items.reduce((acc, i) => {
        return { ...acc, [i.key]: $this.backupItems.find(
          (b) => b.key === i.key)?.value !== i.value || false };
      }, {});
    },
  },
};
</script>

<style scoped>
.attributes-block {
  display: grid;
  grid-template-columns: 1fr auto 2fr auto;
  padding-right: 2rem;
  align-items: baseline;
}

.attributes-item {
  display: contents;
}

.attributes-item > label {
  font-size: 0.9rem;
}
.attributes-item > label:not(.default) {
  font-weight: 500;
}
.attributes-item > label:not(.default)::after {
  content: '*';
}
</style>
