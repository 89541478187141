export default {
  types: {
    extension: 'mdi-puzzle',
    project: 'mdi-wrench',
    site: 'mdi-lan',
    user: 'mdi-account',
  },
  site: {
    studio: 'mdi-home-group',
    user: 'mdi-account',
  },
};
