<template>
  <div id="dashboard" class="main-wrapper pa-8">
    <EntitiesListTemplate
      :items="projects"
      title="Projects"
      type="project"
    ></EntitiesListTemplate>
    <EntitiesListTemplate
      :items="extensions"
      title="Extensions"
      type="extension"
    ></EntitiesListTemplate>
    <EntitiesListTemplate
      :items="sites"
      title="Sites"
      type="site"
      class="mb-4"
    >
      <template v-slot="item">
        <v-icon class="mr-4">{{ icons.site[item.type] }}</v-icon>
        <entity-label :item="item" type="Site"></entity-label>
      </template>
    </EntitiesListTemplate>
    <EntitiesListTemplate
      :items="users"
      title="Users"
      type="user"
    ></EntitiesListTemplate>

    <div></div>
    <div class="app-version">{{ `Les Fées Spéciales | 2024 . v${this.appVersion}` }}</div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import icons from '@/helpers/icons.js';

import EntitiesListTemplate from '@/views/EntitiesListTemplate.vue';

export default {
  name: 'Dashboard',
  components: { EntitiesListTemplate },
  data() {
    return {
      appVersion: '?',
    };
  },
  computed: {
    icons() { return icons; },
    ...mapState({
      extensions: (state) => state.extension.extensions,
      projects: (state) => state.project.projects,
      sites: (state) => state.site.sites,
      users: (state) => state.user.users,
    }),
  },
  async mounted() {
    this.appVersion = await this.$store.dispatch('global/getVersion');
  },
};
</script>

<style>
.list-item {
  text-transform: none !important;
  display: block !important;
  font-size: 1.05rem !important;
  letter-spacing: initial !important;
}

.main-wrapper {
  height: calc(100vh - var(--app-bar-height));
}

#dashboard {
  display: grid;
  column-gap: 16px;
  row-gap: 16px;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 40vh 40vh;
  grid-template-areas: 'a b c'
                       'a b d';
}
#dashboard > * {
  width: 100%;
}
#dashboard > *:nth-child(1) {
  grid-area: a;
}
#dashboard > *:nth-child(2) {
  grid-area: b;
}
#dashboard > *:nth-child(3) {
  grid-area: c;
}
#dashboard > *:nth-child(4) {
  grid-area: d;
}

.app-version {
  font-size: 0.8rem;
  opacity: 0.7;
  text-align: center;
  width: 100%;
}
</style>
