import axios from 'axios';

const state = () => ({
  loading: false,
  innerLoading: false,
  showArchived: false,
});

const getters = {};

const mutations = {
  setLoading(state, { loading }) {
    state.loading = loading;
  },
  setInnerLoading(state, { innerLoading }) {
    state.innerLoading = innerLoading;
  },
  setShowArchived(state, { showArchived }) {
    state.showArchived = showArchived;
  },
};

const actions = {
  async getVersion() {
    try {
      const { version } = (await axios.get('/version')).data;
      return version;
    } catch (err) {
      console.error(err);
      throw err;
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
