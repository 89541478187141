<template>
  <div class="main-wrapper pa-8">
    <EntitiesListTemplate
      :items="users"
      title="Users"
      type="user"
      full-height
    ></EntitiesListTemplate>
  </div>
</template>

<script>
import { mapState } from 'vuex';

import EntitiesListTemplate from '@/views/EntitiesListTemplate.vue';

export default {
  name: 'Users',
  components: { EntitiesListTemplate },
  computed: {
    ...mapState({
      users: (state) => state.user.users,
    }),
  },
};
</script>
