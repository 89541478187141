import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';

import './plugins/global-components.js';
import './plugins/axios.js';
import vuetify from './plugins/vuetify.js';

import './assets/css/styles.scss';

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app');
