<template>
  <div :class="{ 'text--disabled': !item.is_enabled, 'label': true }">
    {{ item.name || item.text }}
    <v-chip
      v-if="'version' in item"
      label class="ml-2"
      :class="{ 'font-weight-bold': item.version.overriden }"
      :ripple="false"
    >
      {{ versionName }}{{ item.version.overriden ? '*' : '' }}
    </v-chip>
    <span class="ml-2" v-if="!item.is_enabled">
      <v-icon color="grey lighten-1">mdi-circle-off-outline</v-icon>
    </span>
    <span class="ml-2" v-if="item.is_archived">
      <v-icon color="grey lighten-1">mdi-archive-arrow-down-outline</v-icon>
    </span>
    <span class="ml-2" v-if="noUse">
      <v-icon color="grey lighten-1">mdi-link-off</v-icon>
    </span>
    <span class="ml-2" v-if="item.categories">
      <v-chip
        v-for="c in item.categories"
        :key="c"
        @click.prevent="$emit('clicked-category', c)"
        small outlined
      >{{ c }}</v-chip>
    </span>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'EntityLabel',
  props: {
    item: { type: Object, required: true },
    type: { type: String, required: true },
  },
  computed: {
    ...mapState({
      extensions: (state) => state.extension.extensions,
      versions: (state) => state.version.versions,
    }),
    versionName() {
      if (this.item.version.uid === '__default') {
        const extUid = this.item.key;
        const ext = this.extensions.find((e) => e.uid === extUid);
        return `<default> (=${this.versions.find((v) => v.uid === ext.defaultVersionUid).name})`;
      }
      return this.versions.find((v) => v.uid === this.item.version.uid).name;
    },
    noUse() {
      if (this.type !== 'Extension') return false;
      return this.$store.getters['extension/isUnused'](this.item.uid);
    },
  },
};
</script>

<style scoped>
.label {
  width: 100%;
  text-overflow: ellipsis;
  overflow-x: hidden;
  white-space: nowrap;
}
</style>
