<template>
  <v-dialog :value="open" @input="(v) => $emit('update-popup', v)" max-width="600px">
    <v-card>
      <v-card-title>
        <span class="text-h5">Delete override</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          Are you sure you want to delete this override?
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="accent"
          text
          @click="$emit('update-popup', false)"
        >
          cancel
        </v-btn>
        <v-btn
          color="primary"
          text
          @click="$emit('delete')"
        >
          delete
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'DeleteOverridePopup',
  props: {
    open: { type: Boolean, required: true },
  },
};
</script>
