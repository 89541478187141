import { makeEntityStore } from '@/store/base-entity.js';

export default makeEntityStore('site', {
  getters: {
    getSiteByName: (state) => (name) => {
      return state.sites.find((s) => s.name === name);
    },
  },

  afterDuplicate: async ({ commit, rootState }, origin, duplicate) => {
    commit('project/addToListInProjects', {
      uids: duplicate.projects.map((i) => i.item),
      key: 'sites',
      value: {
        item: duplicate.uid,
        rel: { label: 'HAS' },
      }
    }, { root: true });

    const assignees = rootState.user.users.filter((u) => {
      return u.sites.some((s) => s.item === origin.uid);
    });
    commit('user/addToListInUsers', {
      uids: assignees.map((a) => a.uid),
      key: 'sites',
      value: {
        item: duplicate.uid,
        rel: { label: 'IN' },
      }
    }, { root: true });
  },

  afterDelete: async ({ commit, state, rootState }, delUid) => {
    const e = state.sites.find((x) => x.uid === delUid);

    commit('project/removeFromListInProjects', {
      uids: e.projects.map((i) => i.item),
      key: 'sites',
      delUid
    }, { root: true });

    const assignees = rootState.user.users.filter((u) => {
      return u.sites.some((s) => s.item === origin.uid);
    });
    commit('user/removeFromListInUsers', {
      uids: assignees.map((a) => a.uid),
      key: 'sites',
      delUid
    }, { root: true });

    commit('setSites', { sites: state.sites.filter((x) => x.uid !== delUid) });
  },

});
