<template>
  <div>
    <v-dialog v-model="showLeaveConfirmationPopup" max-width="600px">
      <v-card>
        <v-card-title>
          <span class="text-h5">Unsaved data</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            You have some unsaved modifications on the current project! Are you sure you want to switch to another one?
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="accent"
            text
            @click="goToNext"
          >
            Discard changes
          </v-btn>
          <v-btn
            color="primary"
            text
            @click="showLeaveConfirmationPopup = false"
          >
            Stay here
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <duplication-popup
      :open="showDuplicationPopup"
      :name="duplicateName"
      :type="type"
      :is-valid="duplicateIsValid"
      @update-popup="(v) => showDuplicationPopup = v"
      @update-name="(v) => duplicateName = v"
      @duplicate="duplicate"
    ></duplication-popup>
    <v-dialog v-model="showDeleteConfirmationPopup" max-width="600px">
      <v-card>
        <v-card-title>
          <span class="text-h5">Delete {{ type }}</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            Are you sure you want to delete this entity?
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="accent"
            text
            @click="showDeleteConfirmationPopup = false"
          >
            cancel
          </v-btn>
          <v-btn
            color="primary"
            text
            @click="remove"
          >
            delete
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <component v-if="createModalComponent !== null" :is="createModalComponent"
      :open="showNewModal"
      @close="showNewModal = false"
      @create="create"
    ></component>

    <div id="content">
      <div id="sidebar">
        <div>
          <v-banner :color="`${colors[type]} lighten-3`"><b>{{ sidebarTitle }}</b></v-banner>

          <div class="px-2" v-if="canCreate">
            <v-btn class="my-2" block color="accent" @click="showNewModal = true">
              {{ createBtnLabel }}
            </v-btn>
          </div>

          <slot name="sidebar-pre"></slot>
          
          <v-text-field
            v-model="searchListed"
            placeholder="Filter..."
            prepend-inner-icon="mdi-magnify"
            outlined
            clearable
            dense
            hide-details
            single-line
            class="px-2 mt-4"
          ></v-text-field>
        </div>
        <div class="sidebar-list mt-2">
          <EntitiesListTemplate
            :items="filteredListedEntities"
            :type="type"
            :active-uid="$route.params.id"
            raw
          ></EntitiesListTemplate>
        </div>
      </div>

      <div>
        <v-banner color="secondary" single-line>
          <v-row no-gutters>
            <v-row no-gutters align="center">
              <div
                class="status-pill"
                :style="{ backgroundColor: entity.is_enabled ? '#00cc22' : '#999999' }"
                @click="toggleEnabled"
              ></div>
              <v-btn
                class="mx-2"
                :color="entity.is_archived ? 'primary': 'normal'"
                icon :elevation="0"
                @click="toggleArchived"
              >
                <v-icon>mdi-archive-arrow-down-outline</v-icon>
              </v-btn>
              <div class="entity-name">{{ name }}<span v-if="entity.is_archived" class="ml-2">(Archived)</span></div>
            </v-row>

            <v-spacer></v-spacer>

            <template v-if="canDuplicate">
              <v-btn
                color="accent"
                text
                @click="showDuplicationPopup = true"
              >duplicate</v-btn>
              <v-divider vertical></v-divider>
            </template>
            <template v-if="canDelete">
              <v-btn
                color="error"
                text
                @click="showDeleteConfirmationPopup = true"
              >delete</v-btn>
              <v-divider vertical></v-divider>
            </template>
            <v-btn
              v-if="isAdmin"
              color="accent"
              text
              :disabled="loading || !dataIsDirty"
              @click="undoChanges"
            >undo changes</v-btn>
            <v-btn
              v-if="isAdmin"
              color="primary"
              :disabled="loading || !dataIsDirty || !attributesAreValid"
              @click="save"
            >save changes</v-btn>
          </v-row>
        </v-banner>
        <div id="main" class="pa-4">
          <RelatedEntitiesLists
            v-if="relationData"
            ref="related"
            :key="relationsRefreshKey"
            :type="modelClass"
            :entity-uid="entity.uid"
            :searched-types="relationData"
            :extra="relatedExtra"
            @add-rel="(v) => $emit('add-rel', v)"
            @del-rel="(v) => $emit('del-rel', v)"
            @upd-extra="(v) => $emit('upd-extra', v)"
          >
          </RelatedEntitiesLists>

          <v-form v-model="attributesAreValid">
            <v-expansion-panels :value="openAttributes" @update="$emit('set-open-attributes')" multiple>
              <slot></slot>
            </v-expansion-panels>
          </v-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import cloneDeep from 'lodash.clonedeep';
import pluralize from 'pluralize';
import rules from '@/helpers/rules.js';
import colors from '@/helpers/colors.js';
import { getLinkIntermediateUids } from '@/helpers/misc.js';

import EntitiesListTemplate from '@/views/EntitiesListTemplate.vue';
import RelatedEntitiesLists from '@/components/RelatedEntitiesLists.vue';
import NewExtensionModal from '@/components/NewExtensionModal.vue';
import NewProjectModal from '@/components/NewProjectModal.vue';
import NewSiteModal from '@/components/NewSiteModal.vue';

export default {
  name: 'EntityTemplate',
  components: {
    EntitiesListTemplate,
    RelatedEntitiesLists,
    NewProjectModal,
    NewExtensionModal,
    NewSiteModal,
  },
  props: {
    listedEntities: { type: Array, default: () => [] },
    entity: { type: Object, default: null },
    dataIsDirty: { type: Boolean, default: false },
    canCreate: { type: Boolean, default: false },
    canDuplicate: { type: Boolean, default: false },
    canDelete: { type: Boolean, default: false },
    duplicateIsValid: { type: Boolean, default: true },
    relatedExtra: { type: Object, default: () => ({}) },
    reloadFn: { type: Function, default: () => {} },
    onDuplicate: { type: Function, default: null },
    name: { type: String, default: '' },
    type: { type: String, required: true },
    createModalComponent: { type: String, default: null },
    sidebarTitle: { type: String, default: 'Entities' },
    createBtnLabel: { type: String, default: 'Create' },
    openAttributes: { type: Array, default: () => [] },
  },
  data() {
    return {
      relationsRefreshKey: 0,

      attributesAreValid: true,
      rules,
      colors,

      nextPath: '/',
      duplicateName: '',

      relationData: null,
      searchListed: '',
      
      showNewModal: false,
      showLeaveConfirmationPopup: false,
      showDuplicationPopup: false,
      showDeleteConfirmationPopup: false,
    };
  },
  computed: {
    ...mapState({
      loading: (state) => state.global.loading,
    }),
    isAdmin() {
      return this.$store.getters['user/isAdmin']();
    },
    modelClass() {
      return this.type[0].toUpperCase() + this.type.slice(1);
    },
    filteredListedEntities() {
      if (!this.searchListed) return this.listedEntities;
      const s = this.searchListed.toLowerCase();
      return this.listedEntities.filter((e) => e.name.toLowerCase().indexOf(s) !== -1);
    },
  },
  methods: {
    redirectToFirstEntity() {
      if (this.filteredListedEntities.length > 0)
        this.$router.push({ name: this.type, params: { id: this.filteredListedEntities[0].uid } });
      else
        this.$router.push({ name: pluralize(this.type) });
    },
    goToNext() {
      this.showLeaveConfirmationPopup = false;
      this.$store.commit(`${this.type}/cleanChanges`);
      if (this.type === 'extension')
        this.$store.commit('version/cleanChanges');
      const $this = this;
      this.$nextTick(() => $this.$router.push(this.nextPath));
    },
    toggleEnabled() {
      this.updateAttribute('is_enabled', !this.entity.is_enabled);
    },
    toggleArchived() {
      this.updateAttribute('is_archived', !this.entity.is_archived);
    },
    updateAttribute(attr, value) {
      this.$store.commit(`${this.type}/updateCurrent`, { key: attr, value });
    },
    undoChanges() {
      this.$store.commit(`${this.type}/undoChanges`);
      this.$emit('undo');
      this.$refs.related.restoreRelationsFromBackup();
      this.relationsRefreshKey++;
    },
    async create(data) {
      try {
        const newEntity = await this.$store.dispatch(`${this.type}/create`, { data });
        this.$router.push({ name: this.type, params: { id: newEntity.uid } });
      } finally {
        this.showNewModal = false;
      }
    },
    async duplicate() {
      try {
        let origin = cloneDeep(this.entity);
        origin.name = this.duplicateName;
        if (this.onDuplicate) origin = this.onDuplicate(origin);
        await this.$store.dispatch(`${this.type}/duplicate`, { origin });
      } finally {
        this.showDuplicationPopup = false;
      }
    },
    async save() {
      this.$store.commit('global/setInnerLoading', { innerLoading: true });
      try {
        const promises = [this.$store.dispatch(`${this.type}/save`)];
        const relationsDiff = this.$store.getters[`${this.type}/getRelationsDiff`]();
        for (const entityType in relationsDiff) {
          const diff = relationsDiff[entityType];
          if (diff.toAdd.length > 0)
            promises.push(this.$store.dispatch('common/linkEntityToMultiple', {
              source_type: this.modelClass, source_uid: this.entity.uid,
              target_type: entityType, target_uids: diff.toAdd,
              ...getLinkIntermediateUids(this.$store, this.relatedExtra, entityType, diff.toAdd)
            }));
          if (diff.toDel.length > 0) {
            let b = this.$store.getters[`${this.type}/getBackupRelations`]()
            const related = { [entityType]: b[entityType].extra };
            promises.push(this.$store.dispatch('common/unlinkEntityFromMultiple', {
              source_type: this.modelClass, source_uid: this.entity.uid,
              target_type: entityType, target_uids: diff.toDel,
              ...getLinkIntermediateUids(this.$store, related, entityType, diff.toDel)
            }));
          }

          // special case: for Project page, check for updated Extension versions
          if (this.type === 'project') {
            const b = this.$store.state.project.projectBackup;
            if (entityType === 'Extension' && diff.toKeep.length > 0) {
              for (const extUid of diff.toKeep) {
                let oldVersion = b.project_extensions.find((e) => e.uid === extUid).version;
                let newVersion = this.entity.project_extensions.find((e) => e.uid === extUid).version;
                if (oldVersion !== newVersion) {
                  const oldIsDefault = oldVersion === '__default';
                  const newIsDefault = newVersion === '__default';
                  if (oldIsDefault)
                    oldVersion = this.$store.state.extension.extensions.find((e) => e.uid === extUid).defaultVersionUid;
                  else if (newIsDefault)
                    newVersion = this.$store.state.extension.extensions.find((e) => e.uid === extUid).defaultVersionUid;
                  promises.push(this.$store.dispatch('common/relinkEntity', {
                    source_type: 'Project',
                    source_uid: this.entity.uid,
                    target_type: 'Version',
                    link_type: 'PROJECT_USES',
                    link_dir: 'from',
                    target_old_uids: [oldVersion],
                    target_new_uids: [newVersion],
                    extra: { is_default: newIsDefault }
                  }));
                }
              }
            }
          }
        }
        await Promise.all(promises);
        this.$store.commit(`${this.type}/pushCurrentRelations`);
      } catch (err) {
        console.error(err);
      } finally {
        this.$store.commit('global/setInnerLoading', { innerLoading: false });
      }
    },
    async remove() {
      try {
        await this.$store.dispatch(`${this.type}/delete`, { uid: this.entity.uid });
        this.$store.commit(`${this.type}/cleanChanges`);
        if (this.type === 'extension')
          this.$store.commit('version/cleanChanges');

        const $this = this;
        this.$nextTick(() => { $this.redirectToFirstEntity() });
      } finally {
        this.showDeleteConfirmationPopup = false;
      }
    },
    checkPageLeave(to, from, next) {
      if (this.dataIsDirty) {
        this.nextPath = to.path;
        this.showLeaveConfirmationPopup = true;
        return false; // cancel the navigation
      } else {
        next();
        if (to.name == from.name) {
          this.reloadFn();
          const $this = this;
          this.$nextTick(() => {
            $this.$refs.related.load();
          });
        }
      }
    },
    setRelationData(data) {
      this.relationData = data;
    },

    openRelatedEntitiesPopup(p) {
      this.$refs.related.setPopup(p, true);
    },
  },
  watch: {
    entity: {
      handler(newEntity) {
        // if entity on page is archived, force global toggle to get
        // a more logical UI
        if (newEntity) {
          this.duplicateName = `${newEntity.name} (Copy)`;
          if (newEntity.is_archived)
            this.$store.commit('global/setShowArchived', { showArchived: true });
        }
      },
      immediate: true,
    }
  },
};
</script>

<style scoped>
#sidebar {
  display: grid;
  grid-template-rows: auto 1fr;
}

.sidebar-list {
  overflow-y: auto;
  max-width: 20vw;
}

.entity-name {
  max-width: 30vw;
  text-overflow: ellipsis;
  overflow-x: hidden;
  white-space: nowrap;
}
</style>
