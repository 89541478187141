<template>
  <component :is="simple ? 'div': 'v-expansion-panel'">
    <v-expansion-panel-header v-if="!simple">{{ title }}</v-expansion-panel-header>
    <component :is="simple ? 'div': 'v-expansion-panel-content'">
      <div class="attributes-block">
        <div v-for="attr in attributes" :key="attr.name" class="attributes-item">
          <label>{{ attr.title }}</label>
          <component
            :is="attr.component"
            :value="data[attr.name]"
            @input="(value) => $emit('update', { attr: attr.name, value })"
            :rules="attr.rules"
            dense
            single-line
            :class="{ 'modified-input': modified[attr.name] }"
            v-bind="attr.extra || {}"
            @click:append="() => attr.onClick ? $emit(attr.onClick) : {}"
          ></component>
        </div>
      </div>
    </component>
  </component>
</template>

<script>
export default {
  name: 'AttributesPanel',
  props: {
    title: { type: String, default: '' },
    attributes: { type: Array, required: true },
    data: { type: Object, required: true },
    backup: { type: Object },
    simple: { type: Boolean, default: false },
  },
  computed: {
    modified() {
      const $this = this;
      return this.attributes.reduce((acc, a) => {
        const m = ($this.backup)
          ? (Array.isArray($this.data[a.name])
              ? JSON.stringify($this.data[a.name]) !== JSON.stringify($this.backup[a.name])
              : $this.data[a.name] !== $this.backup[a.name])
          : false;
        return { ...acc, [a.name]: m };
      }, {});
    },
  },
};
</script>

<style scoped>
.attributes-block {
  display: grid;
  grid-template-columns: 1fr 2fr;
  padding-right: 2rem;
}

.attributes-item {
  display: contents;
}

.attributes-item > label {
  font-weight: 500;
  font-size: 0.9rem;
  text-transform: capitalize;
}
</style>
