export default {
    Project: {
        Site: {
            relType: 'HAS',
            relFrom: false,
        },
        User: {
            relType: 'WORKS_ON',
            relFrom: false,
        },
    },
    Site: {
        Project: {
            relType: 'HAS',
            relFrom: true,
        },
        User: {
            relType: 'IN',
            relFrom: false,
        },
    },
    User: {
        Project: {
            relType: 'WORKS_ON',
            relFrom: true,
        },
        Site: {
            relType: 'IN',
            relFrom: true,
        },
    },
};
