import Vue from 'vue';
import axios from 'axios';
import cloneDeep from 'lodash.clonedeep';
import flatten from 'lodash.flatten';

import { makeEntityStore } from '@/store/base-entity.js';
import { getProjectExtensionVersionUids } from '@/helpers/models/formatters.js';
import { uidsToObjects } from '@/helpers/misc.js';

export default makeEntityStore('project', {
  state: {
    selectedSite: null,
  },

  getters: {
    getUsedProjectNames: (state, _, rootState) => () => {
      if (!state.selectedSite) return [];
      
      const site = uidsToObjects(rootState, 'site', [state.selectedSite], { single: true });
      const existingProjects = uidsToObjects(rootState, 'project', site.projects, { packed: true });
      return existingProjects.map((p) => p.name);
    },
  },

  mutations: {
    setSelectedSite(state, { site }) {
      state.selectedSite = site;
    },
    
    // ENVIRONMENT VARIABLES
    addEnvOverride(state, { type, value }) {
      if (state.currentProject === null) return;
      Vue.set(state.currentProject[`${type}_env`], value, []);
      state.dataIsDirty = true;
    },
    removeEnvOverride(state, { type, value }) {
      if (state.currentProject === null) return;
      const p = cloneDeep(state.currentProject);
      delete p[`${type}_env`][value];
      state.currentProject = p;
      state.dataIsDirty = true;
    },
    // . operations
    addEnvVar(state, { type, item }) {
      if (type === 'project') {
        const env = state.currentProject.env;
        env.push({ key: '', value: '' });
        Vue.set(state.currentProject, 'env', env);
      } else {
        const k = `${type}_env`;
        const env = state.currentProject[k][item];
        env.push({ key: '', value: '' });
        Vue.set(state.currentProject[k], item, env);
      }
      state.dataIsDirty = true;
    },
    removeEnvVar(state, { type, index, item }) {
      if (type === 'project') {
        const env = state.currentProject.env;
        env.splice(index, 1);
        Vue.set(state.currentProject, 'env', env);
      } else {
        const k = `${type}_env`;
        const env = state.currentProject[k][item];
        env.splice(index, 1);
        Vue.set(state.currentProject[k], item, env);
      }
      state.dataIsDirty = true;
    },
    updateEnvVarKey(state, { type, oldKey, key, value, item }) {
      if (type === 'project') {
        const env = state.currentProject.env;
        const idx = env.findIndex((v) => v.key === oldKey);
        env.splice(idx, 1, { key, value });
        Vue.set(state.currentProject, 'env', env);
      } else {
        const k = `${type}_env`;
        const env = state.currentProject[k][item];
        const idx = env.findIndex((v) => v.key === oldKey);
        env.splice(idx, 1, { key, value });
        Vue.set(state.currentProject[k], item, env);
      }
      state.dataIsDirty = true;
    },
    updateEnvVarValue(state, { type, key, value, item }) {
      if (type === 'project') {
        const env = state.currentProject.env;
        env.find((v) => v.key === key).value = value;
        Vue.set(state.currentProject, 'env', env);
      }
      else if (type === 'ext') {
        const ext = state.currentProject.project_extensions;
        const env = ext.find((x) => x.extension === item).overrideEnv;
        const idx = env.findIndex((x) => x.key === key);
        if (idx !== -1) env[idx].value = value;
        else env.push({ key, value });
        Vue.set(state.currentProject, 'project_extensions', ext);
      }
      else {
        const k = `${type}_env`;
        const env = state.currentProject[k][item];
        env.find((v) => v.key === key).value = value;
        Vue.set(state.currentProject[k], item, env);
      }
      state.dataIsDirty = true;
    },
    updateEnvVarOverride(state, { type, key, value }) {
      const k = `${type}_env`;
      const env = cloneDeep(state.currentProject[k]);
      env[value] = env[key];
      delete env[key];
      Vue.set(state.currentProject, k, env);
      state.dataIsDirty = true;
    },
    // EXTENSIONS
    addExtension(state, { extension, version, env }) {
      const exts = state.currentProject.project_extensions;
      exts.push({ extension, version, env });
      Vue.set(state.currentProject, 'project_extensions', exts);
      state.dataIsDirty = true;
    },
    removeExtension(state, { name }) {
      const exts = state.currentProject.project_extensions;
      const idx = exts.findIndex((e) => e.extension === name);
      exts.splice(idx, 1);
      Vue.set(state.currentProject, 'project_extensions', exts);
      state.dataIsDirty = true;
    },
    updateExtension(state, { name, value, version, env }) {
      const exts = state.currentProject.project_extensions;
      const idx = exts.findIndex((e) => e.extension === name);
      exts.splice(idx, 1, { extension: value, version, env });
      Vue.set(state.currentProject, 'project_extensions', exts);
      state.dataIsDirty = true;
    },
    updateExtensionVersion(state, { name, version, env }) {
      const exts = state.currentProject.project_extensions;
      const ext = exts.find((e) => e.extension === name)
      ext.version = version;
      ext.overrideEnv = env;
      Vue.set(state.currentProject, 'project_extensions', exts);
      state.dataIsDirty = true;
    },
    addExtensionOverride(state, { type, newItem, extension }) {
      const k = `${type}_extensions`;
      const overrides = state.currentProject[k][extension] || [];
      overrides.push(newItem);
      Vue.set(state.currentProject[k], extension, overrides);
      state.dataIsDirty = true;
    },
    removeExtensionOverride(state, { type, index, extension }) {
      const k = `${type}_extensions`;
      const overrides = state.currentProject[k][extension];
      overrides.splice(index, 1);
      Vue.set(state.currentProject[k], extension, overrides);
      state.dataIsDirty = true;
    },
    updateExtensionOverrideKey(state, { type, oldKey, key, value, extension, extra }) {
      const k = `${type}_extensions`;
      const overrides = state.currentProject[k][extension];
      const idx = overrides.findIndex((o) => o[type] === oldKey);
      const item = { [type]: key, version: value, ...extra };
      overrides.splice(idx, 1, item);
      Vue.set(state.currentProject[k], extension, overrides);
      state.dataIsDirty = true;
    },
    updateExtensionOverrideValue(state, { type, key, value, extension }) {
      const k = `${type}_extensions`;
      const overrides = state.currentProject[k][extension];
      if (type === 'site')
        overrides.find((o) => o.site === key).version = value;
      else
        overrides.find((o) => o.user === key).version = value;
      Vue.set(state.currentProject[k], extension, overrides);
      state.dataIsDirty = true;
    },

    setDefaultExtensionEnv(state, { projectUid, extensionUid, env }) {
      const idx = state.projects.findIndex((p) => p.uid === projectUid);
      const p = cloneDeep(state.projects[idx]);
      p.project_extensions.find((e) => e.uid === extensionUid).env = env;
      Vue.set(state.projects, idx, p);
    }
  },

  beforeCreate: ({ state }, data) => {
    return { site: state.selectedSite?.uid || null, data };
  },

  beforeSave: async({ rootState }, data) => {
    // extract extension overrides, treated separately
    const site_extensions_uid = flatten(Object.values(data.site_extensions)).map((v) => ({ site: v.code, version: v.version }));
    const user_extensions_uid = flatten(Object.values(data.user_extensions));
    await axios.patch(`/project-extension-overrides/${data.uid}`, {
      site: site_extensions_uid,
      user: user_extensions_uid,
    });

    // extract extension env
    const { versions } = rootState.version;
    const extensionEnv = data.project_extensions.reduce((acc, i) => {
      const vUids = getProjectExtensionVersionUids(data, i.extension);
      const v = versions.filter((ver) => vUids.includes(ver.uid));
      const e = i.overrideEnv.filter((i) => {
        return v.every((ver) => {
          const idx = ver.env.findIndex((x) => x.key === i.key);
          return idx === -1 || ver.env[idx].value !== i.value;
        });
      }).map(({ key, value }) => ({ key, value }));
      if (e.length > 0) acc[i.version] = e;
      return acc;
    }, {});
    await axios.patch(`/project-extension-env/${data.uid}`, extensionEnv);

    return data;
  },

  onAddRelationsToCurrent: ({ commit, rootState }, { entityType, relations }) => {
    if (entityType === 'Extension') {
      for (const rel of relations) {
        const uid = rel.item;
        const version = rel.extra[uid].uid === '__default'
          ? rel.extra[uid].uid
          : rootState.version.versions.find((v) => v.uid === rel.extra[uid].uid).uid;
        commit('addToListInCurrent', {
          key: 'project_extensions',
          value: {
            uid,
            extension: rootState.extension.extensions.find((ext) => ext.uid === uid).name,
            version,
            overrideEnv: [],
          }
        });
      }
    }
  },
  onRemoveRelationsFromCurrent: ({ commit }, { entityType, relations }) => {
    if (entityType === 'Extension') {
      commit('removeFromListInCurrent', { key: 'project_extensions', uid: relations });
    }
  },

  afterDuplicate: async ({ commit, rootState }, origin, duplicate) => {
    commit('site/addToListInSites', {
      uids: duplicate.sites.map((i) => i.item),
      key: 'projects',
      value: {
        item: duplicate.uid,
        rel: { label: 'HAS' },
      }
    }, { root: true });

    const assignees = rootState.user.users.filter((u) => {
      return u.projects.some((p) => p.item === origin.uid);
    });
    commit('user/addToListInUsers', {
      uids: assignees.map((a) => a.uid),
      key: 'projects',
      value: {
        item: duplicate.uid,
        rel: { label: 'WORKS_ON' },
      }
    }, { root: true });
  },

  afterDelete: async ({ commit, state, rootState }, delUid) => {
    const e = state.projects.find((x) => x.uid === delUid);

    commit('site/removeFromListInSites', {
      uids: e.sites.map((i) => i.item),
      key: 'projects',
      delUid
    }, { root: true });

    const assignees = rootState.user.users.filter((u) => {
      return u.projects.some((p) => p.item === origin.uid);
    });
    commit('user/removeFromListInUsers', {
      uids: assignees.map((a) => a.uid),
      key: 'projects',
      delUid
    }, { root: true });

    commit('setProjects', { projects: state.projects.filter((x) => x.uid !== delUid) });
  },

});
