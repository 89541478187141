var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.currentExtension)?_c('EntityTemplate',{ref:"content",attrs:{"listed-entities":_vm.extensions,"entity":_vm.currentExtension,"data-is-dirty":_vm.extDataIsDirty || _vm.verDataIsDirty,"reload-fn":_vm.reload,"name":_vm.currentExtension.name,"type":"extension","can-create":_vm.isAdmin,"can-duplicate":_vm.isAdmin,"can-delete":_vm.isAdmin,"create-modal-component":"NewExtensionModal","sidebar-title":"Extensions","create-btn-label":"Create extension","open-attributes":_vm.openAttributes},on:{"set-open-attributes":(v) => _vm.openAttributes = v,"undo":function($event){return _vm.$store.commit('version/undoChanges')}}},[[_c('duplication-popup',{attrs:{"open":_vm.showVersionDuplicationPopup,"name":_vm.versionDuplicateName,"type":"version"},on:{"update-popup":(v) => _vm.showVersionDuplicationPopup = v,"update-name":(v) => _vm.versionDuplicateName = v,"duplicate":_vm.duplicate}}),_c('AttributesPanel',{attrs:{"title":"Attributes","data":_vm.currentExtension,"backup":_vm.extensionBackup,"attributes":[
        { name: 'name', title: 'Name', component: 'v-text-field', rules: [_vm.rules.required] },
        { name: 'description', title: 'Description', component: 'v-textarea', extra: { 'auto-grow': true, rows: 2 } },
        { name: 'categories', title: 'Categories', component: 'v-combobox', extra: {
          'multiple': true,
          'hide-selected': true,
          'persistent-hint': true,
          'hint': 'Press <Enter> to add a custom category',
          'small-chips': true,
          'items': _vm.defaultCategories,
        } },
      ]},on:{"update":({ attr, value }) => _vm.$refs.content.updateAttribute(attr, value)}}),_c('v-expansion-panel',[_c('v-expansion-panel-header',[_vm._v("Versions")]),_c('v-expansion-panel-content',[_c('v-text-field',{staticClass:"mb-4",attrs:{"placeholder":"Find version...","prepend-inner-icon":"mdi-magnify","outlined":"","clearable":"","dense":"","hide-details":""},model:{value:(_vm.versionSearch),callback:function ($$v) {_vm.versionSearch=$$v},expression:"versionSearch"}}),_c('v-radio-group',{attrs:{"value":_vm.currentExtension.defaultVersionUid,"hide-details":""},on:{"change":_vm.setDefaultVersionUid}},_vm._l((_vm.filteredVersions),function(version){return _c('VersionDisplay',{key:version.uid,ref:"version-display",refInFor:true,attrs:{"version":version,"used-version-names":_vm.getOtherVersionNames(version.uid)},on:{"update":({ attr, value }) => _vm.updateVersionAttr(version.uid, attr, value),"remove":function($event){return _vm.removeVersion(version.uid)},"duplicate":function($event){return _vm.tryDuplicateVersion(version.uid)},"add-env":function($event){return _vm.addVersionEnvVar(version.uid)},"remove-env":(index) => _vm.removeVersionEnvVar(version.uid, index),"update-env-key":({ oldKey, key, value }) => _vm.updateVersionEnvVarKey(version.uid, oldKey, key, value),"update-env-value":({ key, value }) => _vm.updateVersionEnvVarValue(version.uid, key, value)}})}),1),_c('v-row',{attrs:{"no-gutters":"","align":"center"}},[_c('v-btn',{staticClass:"mr-2",attrs:{"icon":"","outlined":"","x-small":"","elevation":0},on:{"click":_vm.addVersion}},[_c('v-icon',[_vm._v("mdi-plus")])],1),_c('div',[_vm._v("Add version")])],1)],1)],1)]],2):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }