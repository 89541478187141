<template>
  <v-text-field
    :value="value"
    @input="(v) => $emit('input', v)"
    :rules="rules"
    dense
    single-line
    append-icon="mdi-open-in-new"
    @click:append="openUrl"
  ></v-text-field>
</template>

<script>
export default {
  name: 'VTextUrl',
  props: {
    value: { type: String, required: true },
    rules: { type: Array, default: () => [] },
  },
  methods: {
    openUrl(target = '_blank') {
      if (this.value) window.open(this.value, target);
    }
  },
};
</script>

