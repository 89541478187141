export const objGet = (obj, key, dft = null) => {
  return (key in obj) ? obj[key] : dft;
};

export const uidsToObjects = (storeState, entityType, uids, options) => {
  const keepOrder = objGet(options, 'keepOrder', false);
  const single = objGet(options, 'single', false);
  const packed = objGet(options, 'packed', false);

  if (packed) uids = uids.map((i) => i.item);

  const s = storeState[entityType];
  const v = keepOrder
    ? uids.map((uid) => s[`${entityType}s`].find((i) => i.uid === uid))
    : s[`${entityType}s`].filter((i) => uids.includes(i.uid));
  return single ? v[0] : v;
};

export const computeEntityDifference = (from, to) => {
  const toAdd = [], toDel = [];

  for (const e of from)
    if (!to.includes(e))
      toDel.push(e);
  for (const e of to)
    if (!from.includes(e))
      toAdd.push(e);
    
  return { toAdd, toDel };
};

// SPECIALS
export const getMongoUrlPrefix = (project, secret = true) => {
  let s = 'mongodb://';
  if (project && project.mongo_user && project.mongo_password) {
    const pwd = secret ? '•'.repeat(project.mongo_password.length) : project.mongo_password;
    s += `${project.mongo_user}:${pwd}@`;
  }
  return s;
};

export const getLinkIntermediateUids = (store, related, entityType, uids) => {
  if (!related || !(entityType in related)) return {};
  const intermediate_uids = {};
  const extra = {};
  for (const uid of uids) {
    if (uid in related[entityType]) {
      const x = related[entityType][uid];
      let iUid = x.uid;
      if (x.type === 'Version' && iUid === '__default') {
        const ext = store.state.extension.extensions.find((e) => e.uid === uid);
        iUid = ext.defaultVersionUid;
        extra[iUid] = { is_default: true };
      }
      intermediate_uids[uid] = { [x.type]: { uid: iUid, link: x.link } };
    }
  }
  return { intermediate_uids, extra };
}
