import Vue from 'vue';
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css';
import colors from 'vuetify/es5/util/colors';

Vue.use(Vuetify);

const opts = {
  theme: {
    themes: {
      light: {
        primary: colors.amber.darken2,
        accent: colors.grey.darken3,
        secondary: colors.grey.lighten3,
        info: colors.teal.lighten1,
        warning: colors.amber.base,
        error: colors.deepOrange.accent3,
        success: colors.green.accent3
      },
      dark: {
        primary: colors.amber.darken2,
        accent: colors.grey.darken3,
        secondary: colors.amber.darken3,
        info: colors.teal.lighten1,
        warning: colors.amber.base,
        error: colors.deepOrange.accent3,
        success: colors.green.accent3
      }
    }
  }
};

export default new Vuetify(opts);
