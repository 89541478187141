<template>
  <EntityTemplate
    ref="content"
    v-if="currentSite"
    :listed-entities="sites"
    :entity="currentSite"
    :data-is-dirty="dataIsDirty"
    :reload-fn="reload"
    :on-duplicate="onDuplicate"
    :duplicate-is-valid="duplicateIsValid"
    :name="currentSite.name"
    type="site"
    :can-create="isAdmin"
    :can-duplicate="isAdmin"
    :can-delete="isAdmin"
    sidebar-title="Sites"
    create-modal-component="NewSiteModal"
    create-btn-label="Create site"
    :open-attributes="openAttributes"
    @set-open-attributes="(v) => openAttributes = v"
  >
    <template #duplicate>
      <v-text-field
        v-model="duplicateCode"
        label="New code"
        class="mt-4"
        :rules="[rules.required, duplicateHasValidCode || 'Another site already uses this code']"
      ></v-text-field>
    </template>

    <template>
      <AttributesPanel
        title="Global"
        :data="currentSite"
        :backup="siteBackup"
        :attributes="[
          { name: 'code', title: 'Code', component: 'v-text-field', rules: [rules.required] },
          { name: 'name', title: 'Name', component: 'v-text-field', rules: [rules.required] },
          { name: 'description', title: 'Description', component: 'v-textarea', extra: { 'auto-grow': true, rows: 2 } },
          { name: 'type', title: 'Type', component: 'v-select', rules: [rules.required], extra: {
            items: [
              { value: 'studio', text: 'Studio' },
              { value: 'user', text: 'Remote' },
            ]
          } },
        ]"
        @update="({ attr, value }) => $refs.content.updateAttribute(attr, value)"
      />
    </template>

  </EntityTemplate>
</template>

<script>
import { mapState } from 'vuex';
import rules from '@/helpers/rules.js';

import EntityTemplate from '@/views/EntityTemplate.vue';
import AttributesPanel from '@/components/AttributesPanel.vue';

export default {
  name: 'Site',
  components: {
    EntityTemplate,
    AttributesPanel,
  },
  data() {
    return {
      openAttributes: [],
      rules,

      duplicateCode: '',
    };
  },
  computed: {
    ...mapState({
      sites: (state) => state.site.sites,
      currentSite: (state) => state.site.currentSite,
      siteBackup: (state) => state.site.siteBackup,
      dataIsDirty: (state) => state.site.dataIsDirty,
    }),
    isAdmin() {
      return this.$store.getters['user/isAdmin']();
    },
    usedSiteCodes() {
      return this.sites ? this.sites.map((s) => s.code) : [];
    },
    duplicateHasValidCode() {
      return !this.usedSiteCodes.includes(this.duplicateCode);
    },
    duplicateIsValid() {
      return this.duplicateCode && this.duplicateHasValidCode;
    },
  },
  methods: {
    async load() {
      this.$store.commit('global/setInnerLoading', { innerLoading: true });
      await this.prepareData();
    },
    async reload() {
      await this.load();
    },
    async prepareData() {
      if (this.sites.length === 0)
        await this.$store.dispatch('site/getSites');

      const site = this.sites.find((s) => s.uid === this.$route.params.id);
      this.$store.commit('site/setCurrent', { currentSite: site });
      this.duplicateCode = `${site.code}_copy`;

      this.openAttributes = [
        0, // attributes
      ];

      const $this = this;
      this.$nextTick(() => {
        $this.$refs.content.setRelationData({
          Project: { title: 'Linked projects', modifiable: true },
          User: { title: 'Assigned users', modifiable: true },
        });
      });
      this.$store.commit('global/setInnerLoading', { innerLoading: false });
    },
    onDuplicate(data) {
      data.code = this.duplicateCode;
      return data;
    },
  },
  created() {
    this.load();
  },

  beforeRouteUpdate(to, from, next) {
    this.$refs.content.checkPageLeave(to, from, next);
  },
  beforeRouteLeave(to, from, next) {
    this.$refs.content.checkPageLeave(to, from, next);
  },
};
</script>
