<template>
  <div v-if="version.name !== '<disabled>'">
    <div v-if="open" class="mb-2">
      <v-row class="mb-3" no-gutters align="center">
        <v-radio :value="version.uid"></v-radio>
        <v-icon class="mx-1" @click="open = false">mdi-chevron-down</v-icon>
  
        <div
          class="status-pill mr-2"
          :style="{ backgroundColor: version.is_enabled ? '#00cc22' : '#999999' }"
          @click="$emit('update', { attr: 'is_enabled', value: !version.is_enabled })"
        ></div>

        <v-text-field
          :value="version.name"
          @input="(value) => $emit('update', { attr: 'name', value })"
          dense
          label="Name"
          single-line
          :rules="[rules.required, rules.nameNotUsed]"
          class="mr-2"
        ></v-text-field>

        <v-btn fab outlined x-small :elevation="0" @click="$emit('duplicate')" class="mr-2">
          <v-icon>mdi-content-copy</v-icon>
        </v-btn>
        <v-btn fab outlined x-small :elevation="0" @click="$emit('remove')">
          <v-icon>mdi-trash-can-outline</v-icon>
        </v-btn>
      </v-row>

      <v-form v-model="attributesAreValid">
        <v-alert outlined color="accent" class="ml-4">
          <AttributesPanel
            simple
            :data="version"
            :backup="backup"
            :attributes="attributes"
            @update="({ attr, value }) => $emit('update', { attr, value })"
          />
        </v-alert>
      </v-form>

      <v-alert
        border="left"
        color="primary lighten-1"
        class="ml-4 py-2"
        colored-border
        :elevation="1"
      >
        <EnvVarsBlock
          class="ml-4"
          :items="version.env"
          :backup-items="backup?.env || []"
          :block-id="`version-env-vars-${version.uid}`"
          add-label="Add variable"
          @add="$emit('add-env')"
          @remove="(i) => $emit('remove-env', i)"
          @update-key="({ oldKey, key, value }) => $emit('update-env-key', { oldKey, key, value })"
          @update-value="({ key, value }) => $emit('update-env-value', { key, value })" />
      </v-alert>
    </div>
    <div v-else class="mb-2">
      <v-row no-gutters align="center">
        <v-radio :value="version.uid"></v-radio>
        <v-icon class="mx-1" @click="open = true">mdi-chevron-right</v-icon>
        <div>{{ version.name }}</div>
      </v-row>
    </div>
  </div>
</template>

<script>
import rules from '@/helpers/rules.js';

import AttributesPanel from '@/components/AttributesPanel.vue';
import EnvVarsBlock from '@/components/EnvVarsBlock.vue';

export default {
  name: 'VersionDisplay',
  components: { AttributesPanel, EnvVarsBlock },
  props: {
    version: { type: Object, required: true },
    usedVersionNames: { type: Array, required: true },
  },
  data() {
    return {
      open: false,
      rules: {
        ...rules,
        nameNotUsed: (value) => !this.usedVersionNames.includes(value) || 'Name already taken',
      },
      attributesAreValid: true,
    };
  },
  computed: {
    backup() {
      const b = this.$store.getters['version/getBackup']();
      return b.find((v) => v.uid === this.version.uid);
    },
    attributes() {
      const attrs = [
        { name: 'description', title: 'Description', component: 'v-textarea', extra: { 'auto-grow': true, rows: 2 } },
        { name: 'service', title: 'Service', component: 'v-select', rules: [rules.required], extra: {
          items: [
            { value: 'gitlab', text: 'Gitlab' },
            { value: 'github', text: 'Github' },
            { value: 'pypi', text: 'PyPi' },
            { value: 'path', text: 'Path' },
            { value: 'gitlab-url', text: 'Gitlab (URL)' },
            { value: 'github-url', text: 'Github (URL)' },
          ]
        } }
      ];
      if (this.version.service === 'gitlab' || this.version.service === 'github') {
        attrs.push({ name: 'repo_project', title: 'Project', component: 'v-text-field', rules: [rules.required] });
        attrs.push({ name: 'repo_group', title: 'Group', component: 'v-text-field', rules: [rules.required] });
        attrs.push({ name: 'repo_ref_type', title: 'Reference type', component: 'v-select', rules: [rules.required], extra: {
          items: [
            { value: 'branch', text: 'Branch' },
            { value: 'commit', text: 'Commit' },
          ]
        } });
        attrs.push({ name: 'repo_ref', title: 'Reference', component: 'v-text-field', rules: [rules.required] });
        attrs.push({ name: 'repo_token', title: 'Token', component: 'v-text-field', rules: [] });
      }
      else if (this.version.service === 'pypi') {
        attrs.push({ name: 'pypi', title: 'PyPi reference', component: 'v-text-field', rules: [rules.required] });
        attrs.push({ name: 'pip_deps', title: 'Pip dependencies', component: 'v-text-field', rules: [] });
      }
      else if (this.version.service === 'path') {
        attrs.push({ name: 'path', title: 'Path', component: 'v-text-field', rules: [rules.required] });
      }
      else if (this.version.service === 'gitlab-url' || this.version.service === 'github-url') {
        attrs.push({ name: 'url', title: 'URL', component: 'v-text-url', rules: [rules.required, rules.url] });
      }
      return attrs;
    },
  },
  methods: {
    checkAttributesAreValid() { return this.attributesAreValid; },
  },
};
</script>

