<template>
  <v-app-bar app>
    <v-toolbar-title><v-btn id="home-button" plain to="/">Overseer</v-btn></v-toolbar-title>
    <div class="ml-8" v-if="user">
      <router-link class="mr-4" to="/">
        <v-icon :color="$route.name === 'dashboard' ? 'primary' : 'normal'">mdi-view-dashboard</v-icon>
      </router-link>
      <router-link class="mr-4" :to="{ name: 'sites' }">
        <v-icon :color="$route.name.includes('site') ? `${colors.site} lighten-1` : 'normal'">{{ typeIcons['site'] }}</v-icon>
      </router-link>
      <router-link class="mr-4" :to="{ name: 'projects' }">
        <v-icon :color="$route.name.includes('project') ? `${colors.project} lighten-1` : 'normal'">{{ typeIcons['project'] }}</v-icon>
      </router-link>
      <router-link class="mr-4" :to="{ name: 'extensions' }">
        <v-icon :color="$route.name.includes('extension') ? `${colors.extension} lighten-1` : 'normal'">{{ typeIcons['extension'] }}</v-icon>
      </router-link>
      <router-link :to="{ name: 'users' }">
        <v-icon :color="$route.name.includes('user') ? `${colors.user} lighten-1` : 'normal'">{{ typeIcons['user'] }}</v-icon>
      </router-link>
    </div>
    <v-spacer></v-spacer>
    <v-autocomplete
      :key="searchKey"
      ref="search"
      prepend-inner-icon="mdi-magnify"
      placeholder="Find entity..."
      v-model="searchSelect"
      :items="searchItems"
      :search-input.sync="search"
      :filter="searchFilter"
      filled
      rounded
      dense
      hide-details
      hide-no-data
      class="mr-4"
      @change="goToSearchItem"
    >
      <template v-slot:selection="data"> <!-- in text field -->
        {{ data.item.name }}
      </template>
      <template v-slot:item="data"> <!-- in list -->
        <v-list-item-avatar>
          <v-icon>{{ data.item.icon }}</v-icon>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>{{ data.item.name }}</v-list-item-title>
          <v-list-item-subtitle v-if="data.item.code">{{ data.item.code }}</v-list-item-subtitle>
        </v-list-item-content>
      </template>
    </v-autocomplete>

    <v-checkbox
      :value="showArchived"
      @change="$store.commit('global/setShowArchived', { showArchived: !showArchived })"
      label="Show archived"
      hide-details
      class="mr-4"
    ></v-checkbox>

    <div :style="{ display: 'flex', alignItems: 'center' }">
      <div v-if="user" class="text-button mr-2">{{ user.name || user.login }}</div>
      <v-btn icon :elevation="0" @click="logout"><v-icon>mdi-power</v-icon></v-btn>
    </div>
  </v-app-bar>
</template>

<script>
import { mapState } from 'vuex';
import pluralize from 'pluralize';
import icons from '@/helpers/icons.js';
import colors from '@/helpers/colors.js';

export default {
  name: 'AppBar',
  data() {
    return {
      typeIcons: icons.types,
      colors,

      searchItems: [],
      searchSelect: null,
      search: '',
      searchKey: 0,
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.user.user,
      showArchived: (state) => state.global.showArchived,
    }),
  },
  methods: {
    async logout() {
      await this.$store.dispatch('user/logout');
      this.$router.push({ name: 'login' });
    },
    fillSearchItems() {
      const items = [];
      const entityTypes = ['site', 'project', 'extension', 'user'];
      for (const entityType of entityTypes) {
        const list = this.$store.state[entityType][pluralize(entityType)];
        for (const item of list) {
          items.push({
            code: item.code || item.login,
            name: item.name,
            icon: icons.types[entityType],
            link: { name: entityType, params: { id: item.uid } },
          });
        }
      }
      this.searchItems = items;
    },
    searchFilter (item, queryText/*, itemText */) {
      const s = queryText.toLowerCase();
      return item.name.toLowerCase().indexOf(s) !== -1 || (item.code && item.code.toLowerCase().indexOf(s) !== -1);
    },
    goToSearchItem(item) {
      if (!item) return;
      this.$router.push(item.link);
      this.searchSelect = null;
      this.search = '';
      this.searchKey++;
    }
  },
  watch: {
    search(val) {
      if (val) {
        if (this.searchItems.length === 0)
          this.fillSearchItems(val);
      } else {
        this.searchItems = [];
      }
    }
  },
};
</script>

<style scoped>
a {
  text-decoration: none;
}

#home-button {
  text-transform: none;
  padding: 0;
  font-size: 1.1rem;
  letter-spacing: inherit;
}
</style>
