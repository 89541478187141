import axios from 'axios';

const state = () => ({});
const getters = {};
const mutations = {};

const actions = {
  async getEntityNeighbors(_, { type, uid, searchedTypes }) {
    const { data } = await axios.patch(`/entity-neighbors/${type}/${uid}`, searchedTypes);
    return data;
  },
  async linkEntityToMultiple(_, data) {
    await axios.patch('/entity-link-to-multiple', data);
    return data;
  },
  async unlinkEntityFromMultiple(_, data) {
    await axios.patch('/entity-unlink-from-multiple', data);
    return data;
  },
  async relinkEntity(_, data) {
    await axios.patch('/entity-relink', data);
    return data;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
