<template>
  <div v-if="raw" :class="className">
    <v-row no-gutters>
      <slot name="before-list-search"></slot>
      <slot name="list-search"></slot>
    </v-row>
    <v-list>
      <v-list-item-group :value="activeUid">
        <v-list-item
          v-for="item in filteredItems"
          :key="item.uid"
          :value="item.uid"
          :to="{ name: type, params: { id: item.uid } }"
          :class="{ 'text--disabled': !item.is_enabled }"
        >
          <slot v-bind="item">
            <entity-label :item="item" :type="modelClass"></entity-label>
          </slot>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </div>
  <v-card v-else elevation="4" :class="[className, 'mx-auto']" :style="style">
    <v-card-title>{{ title }}</v-card-title>
    <v-card-text class="list-inner">
      <v-row no-gutters>
        <slot name="before-list-search"></slot>
        <slot name="list-search">
          <v-text-field
            v-model="search"
            placeholder="Filter..."
            prepend-inner-icon="mdi-magnify"
            outlined
            clearable
            dense
            hide-details
            single-line
          ></v-text-field>
        </slot>
      </v-row>
      <v-list>
        <v-list-item-group :value="activeUid">
          <v-list-item
            v-for="item in filteredItems"
            :key="item.uid"
            :value="item.uid"
            :to="{ name: type, params: { id: item.uid } }"
            :class="{ 'text--disabled': !item.is_enabled }"
          >
            <slot v-bind="item">
              <entity-label :item="item" :type="modelClass" @clicked-category="(c) => $emit('clicked-category', c)"></entity-label>
            </slot>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'EntitiesListTemplate',
  props: {
    items: { type: Array, default: () => [] },
    title: { type: String, default: '' },
    type: { type: String, required: true },
    activeUid: { type: String, default: '' },
    className: { type: String, default: '' },
    raw: { type: Boolean, default: false },
    fullHeight: { type: Boolean, default: false },
    filterArchived: { type: Boolean, default: true },
  },
  data() {
    return {
      search: '',
    };
  },
  computed: {
    ...mapState({
      showArchived: (state) => state.global.showArchived,
    }),
    modelClass() {
      return this.type[0].toUpperCase() + this.type.slice(1);
    },
    style() {
      const s = {};
      if (this.fullHeight) s.height = '100%';
      return s;
    },
    filteredItems() {
      let filtered = this.items;
      const s = this.search.toLowerCase();
      if (this.filterArchived && !this.showArchived)
        filtered = filtered.filter((i) => !i.is_archived);
      if (s)
        filtered = filtered.filter((i) => i.name.toLowerCase().indexOf(s) !== -1);
      return filtered;
    },
  }
};
</script>

<style scoped>
.list-inner {
  display: grid;
  grid-template-rows: auto 1fr;
  height: calc(100% - 64px);
}

.list-inner:deep(.v-list) {
  overflow-y: auto;
}
</style>
