<template>
  <div>
    <div>
      <div v-for="item, i in items" :key="`${blockId}-${i}`">
        <v-row class="mt-1 mb-3" no-gutters align="center">
          <div>{{ item.text }}</div>
          <v-spacer></v-spacer>
          <v-btn class="ml-2" fab outlined x-small :elevation="0" @click="$emit('remove', item.value)">
            <v-icon>mdi-trash-can-outline</v-icon>
          </v-btn>
        </v-row>
        <slot :item="item.value"></slot>
      </div>
    </div>
    <v-row v-if="canAdd" class="mt-1" no-gutters align="center">
      <v-btn class="mr-2" icon outlined x-small :elevation="0" @click="$emit('add')">
        <v-icon>mdi-plus</v-icon>
      </v-btn>
      <div>{{ addLabel }}</div>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'EnvVarsWrapperBlock',
  props: {
    blockId: { type: String, required: true },
    addLabel: { type: String, default: 'Add item' },
    canAdd: { type: Boolean, default: true },
    items: { type: Array, required: true },
  },
};
</script>

<style scoped>
.attributes-block {
  display: grid;
  grid-template-columns: 1fr auto 2fr auto;
  padding-right: 2rem;
  align-items: baseline;
}

.attributes-item {
  display: contents;
}
</style>
