<template>
  <v-dialog :value="open" max-width="50vw" scrollable @click:outside="$emit('close')" @keydown.esc="$emit('close')">
    <v-card>
      <v-card-title>
        <span v-if="selectedSite" class="text-h5">
          New project - Site: <b>{{ selectedSite.name || selectedSite.code }}</b>
        </span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-form ref="form" v-model="formIsValid">
            <div class="text-h6">Global</div>
            <div class="attributes-block">
              <div class="attributes-item">
                <label>Name</label>
                <v-text-field
                  v-model="name"
                  :rules="[rules.required, rules.nameNotUsed]"
                  dense
                ></v-text-field>
              </div>
              <div class="attributes-item">
                <label>Libreflow</label>
                <v-text-field
                  v-model="libreflow"
                  :rules="[rules.required, rules.semver]"
                  dense
                ></v-text-field>
              </div>
            </div>

            <div class="text-h6">Redis</div>
            <div class="attributes-block">
              <div class="attributes-item">
                <label>URL</label>
                <v-text-field
                  v-model="redisUrl"
                  :rules="[rules.required]"
                  dense
                ></v-text-field>
              </div>
              <div class="attributes-item">
                <label>Password</label>
                <v-text-field
                  v-model="redisPassword"
                  :rules="[rules.required]"
                  dense
                  :type="showRedisPassword ? 'text' : 'password'"
                  :append-icon="showRedisPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append="showRedisPassword = !showRedisPassword"
                ></v-text-field>
              </div>
              <div class="attributes-item">
                <label>Cluster</label>
                <v-text-field
                  v-model="redisCluster"
                  :rules="[rules.required]"
                  dense
                ></v-text-field>
              </div>
            </div>

            <div class="text-h6">Mongo</div>
            <div class="attributes-block">
              <div class="attributes-item">
                <label>URL</label>
                <v-text-field
                  v-model="mongoUrl"
                  :rules="[rules.required]"
                  dense
                ></v-text-field>
              </div>
              <div class="attributes-item">
                <label>User</label>
                <v-text-field
                  v-model="mongoUser"
                  :rules="[rules.required]"
                  dense
                ></v-text-field>
              </div>
              <div class="attributes-item">
                <label>Password</label>
                <v-text-field
                  v-model="mongoPassword"
                  :rules="[rules.required]"
                  dense
                  :type="showMongoPassword ? 'text' : 'password'"
                  :append-icon="showMongoPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append="showMongoPassword = !showMongoPassword"
                ></v-text-field>
              </div>
            </div>

            <div class="text-h6">Exchange server</div>
            <div class="attributes-block">
              <div class="attributes-item">
                <label>URL</label>
                <v-text-field
                  v-model="exchangeServerUrl"
                  :rules="[rules.required]"
                  dense
                ></v-text-field>
              </div>
              <div class="attributes-item">
                <label>Login</label>
                <v-text-field
                  v-model="exchangeServerLogin"
                  :rules="[rules.required]"
                  dense
                ></v-text-field>
              </div>
              <div class="attributes-item">
                <label>Password</label>
                <v-text-field
                  v-model="exchangeServerPassword"
                  :rules="[rules.required]"
                  dense
                  :type="showExchangeServerPassword ? 'text' : 'password'"
                  :append-icon="showExchangeServerPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append="showExchangeServerPassword = !showExchangeServerPassword"
                ></v-text-field>
              </div>
              <div class="attributes-item">
                <label>Bucket</label>
                <v-text-field
                  v-model="exchangeServerBucket"
                  :rules="[rules.required]"
                  dense
                ></v-text-field>
              </div>
            </div>
          </v-form>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="accent"
          text
          @click="$emit('close')"
        >
          Cancel
        </v-btn>
        <v-btn
          color="primary"
          text
          :disabled="!formIsValid"
          @click="createProject"
        >
          Create
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import rules from '@/helpers/rules.js';
import { project as projectDefaults } from '@/helpers/models/defaults.js';

export default {
  name: 'NewProjectModal',
  props: {
    open: { type: Boolean, required: true },
  },
  data() {
    return {
      formIsValid: false,
      rules: {
        ...rules,
        nameNotUsed: (value) => !this.getUsedProjectNames().includes(value) || 'Name already taken',
      },

      name: '',
      libreflow: projectDefaults.libreflow,

      redisUrl: projectDefaults.redisUrl,
      redisPassword: projectDefaults.redisPassword,
      redisCluster: projectDefaults.redisCluster,

      mongoUrl: projectDefaults.mongoUrl,
      mongoUser: projectDefaults.mongoUser,
      mongoPassword: projectDefaults.mongoPassword,

      exchangeServerUrl: projectDefaults.exchangeServerUrl,
      exchangeServerLogin: projectDefaults.exchangeServerLogin,
      exchangeServerPassword: projectDefaults.exchangeServerPassword,
      exchangeServerBucket: projectDefaults.exchangeServerBucket,

      showRedisPassword: false,
      showMongoPassword: false,
      showExchangeServerPassword: false,
    };
  },
  computed: {
    ...mapState({
      selectedSite: (state) => state.project.selectedSite,
    }),
    ...mapGetters({
      getUsedProjectNames: 'project/getUsedProjectNames',
    }),
  },
  methods: {
    createProject() {
      const data = {
        name: this.name,
        libreflow: this.libreflow,

        redis_url: this.redisUrl,
        redis_password: this.redisPassword,
        redis_cluster: this.redisCluster,

        mongo_url: this.mongoUrl,
        mongo_user: this.mongoUser,
        mongo_password: this.mongoPassword,

        exchange_server_url: this.exchangeServerUrl,
        exchange_server_login: this.exchangeServerLogin,
        exchange_server_password: this.exchangeServerPassword,
        exchange_server_bucket: this.exchangeServerBucket,
      };
      this.$emit('create', data);

      // reset modal
      this.name = '';
      this.libreflow = projectDefaults.libreflow;
      this.redisUrl = projectDefaults.redisUrl;
      this.redisPassword = projectDefaults.redisPassword;
      this.redisCluster = projectDefaults.redisCluster;
      this.mongoUrl = projectDefaults.mongoUrl;
      this.mongoUser = projectDefaults.mongoUser;
      this.mongoPassword = projectDefaults.mongoPassword;
      this.exchangeServerUrl = projectDefaults.exchangeServerUrl;
      this.exchangeServerLogin = projectDefaults.exchangeServerLogin;
      this.exchangeServerPassword = projectDefaults.exchangeServerPassword;
      this.exchangeServerBucket = projectDefaults.exchangeServerBucket;
      this.$refs.form.resetValidation();
    },
  },
};
</script>

<style scoped>
.attributes-block {
  display: grid;
  grid-template-columns: 1fr 2fr;
  padding-right: 2rem;
  align-items: center;
}

.attributes-item {
  display: contents;
}

.attributes-item > label {
  font-weight: 500;
  font-size: 0.9rem;
  text-transform: capitalize;
  margin-left: 2rem;
}
</style>
