export const sortExtensions = (e) => {
    e.sort((x1, x2) => x1.name < x2.name ? -1 : 1);
    return e;
};

export const sortProjects = (p) => {
    p.sort((x1, x2) => x1.name < x2.name ? -1 : 1);
    return p;
};

export const sortSites = (s) => {
    s.sort((x1, x2) => x1.name < x2.name ? -1 : 1);
    return s;
};

export const sortUsers = (u) => {
    u.sort((x1, x2) => x1.name < x2.name ? -1 : 1);
    return u;
};

export const sortVersions = (v) => {
    v.sort((x1, x2) => x1.name < x2.name ? -1 : 1);
    return v;
};
